import { Close, Upload } from '@mui/icons-material';
import { Box, Button, Divider, IconButton, Modal, Typography, useMediaQuery, useTheme } from '@mui/material';
import toGeoJSON from '@mapbox/togeojson';
import shp from 'shpjs';
import JSZip from 'jszip';
import { useDropzone } from 'react-dropzone';
import { useCallback, useState } from 'react';
import { coordinatesUtils } from '../../Utils/coordinate.utils';
import { datadogLogs } from '@datadog/browser-logs';

export interface LoadAOIComponentProps {
  onLoadAOI: (geojson: GeoJSON.FeatureCollection, fileName?: string) => void;
  onError?: (error: Error) => void;
  id?: string;
}

export const LoadAOIComponent: React.FC<LoadAOIComponentProps> = ({ onLoadAOI, onError, id }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const toGeojson = async (
    fileType: string,
    fileContent: string | ArrayBuffer,
  ): Promise<GeoJSON.FeatureCollection> => {
    switch (fileType) {
      case 'geojson':
        return JSON.parse(fileContent as string);
      case 'kmz':
        const zip = new JSZip();
        const content = await zip.loadAsync(fileContent as ArrayBuffer);
        const kmlFileName = Object.keys(content.files).find((key) => key.endsWith('.kml'));
        if (!kmlFileName) {
          datadogLogs.logger.error('No KML file found in KMZ', {
            content: content.files,
            name: 'toGeojson',
            fileType,
          });
          throw new Error('No KML file found in KMZ');
        }
        const kmlFile = content.files[kmlFileName];
        const extractedKml = await kmlFile.async('string');
        return toGeojson('kml', extractedKml);
      case 'kml':
        const kml = new DOMParser().parseFromString(fileContent as string, 'text/xml');
        return toGeoJSON.kml(kml);
      case 'gpx':
        return toGeoJSON.gpx(fileContent);
      case 'zip':
      case 'shp':
        return await shp(fileContent);
    }
  };

  const loadAOI = (file) => {
    if (file) {
      const fileType = file.name.split('.').slice(-1)[0]?.toLowerCase();
      const reader = new FileReader();

      reader.onload = async (e) => {
        try {
          let geojson = await toGeojson(fileType, e.target.result as any);
          geojson.features &&
            Array.isArray(geojson.features) &&
            (geojson.features = geojson.features.filter((f) => (f.geometry as any)?.coordinates?.length > 0));
          if (
            (geojson as any)?.crs?.properties?.name === 'urn:ogc:def:crs:EPSG::27700' ||
            (geojson?.features[0]?.geometry as any)?.coordinates[0][0][0] > 180
          ) {
            geojson = coordinatesUtils.toWGS84(geojson); // Convert to WGS84 if in OSGB36 and converts to 6 decimal coordinates
          } else {
            try {
              coordinatesUtils.compress(geojson);
            } catch (err) {
              datadogLogs.logger.warn('Error compressing coordinates', {
                error: err,
                fileType,
                fileName: file.name,
              });
            }
          }
          onLoadAOI(geojson, file.name);
        } catch (err) {
          datadogLogs.logger.error('Error loading AOI', {
            error: err,
            name: 'loadAOI',
            fileType,
            fileContent: e.target.result,
          });
          onError?.(err as Error);
        }
      };
      if (fileType === 'kmz' || fileType === 'zip') {
        reader.readAsArrayBuffer(file);
      } else {
        reader.readAsText(file);
      }
    }
  };

  return (
    <>
      <Button id={id} variant="contained" onClick={() => setModalOpen(true)} className="hide-on-fullscreen">
        {!isMobile && 'Upload File'}
        <Upload />
      </Button>
      <FileUploadModal open={modalOpen} onClose={() => setModalOpen(false)} onFileLoad={loadAOI} />
    </>
  );
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '860px',
  minHeight: '400px',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 2,
  outline: 'none',
  borderRadius: 2,
};

const FileUploadModal = ({ open, onClose, onFileLoad }) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        onFileLoad(acceptedFiles[0]);
        onClose();
      }
    },
    [onFileLoad, onClose],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/geo+json': ['.geojson'],
      'application/vnd.google-earth.kmz': ['.kmz'],
      'application/vnd.google-earth.kml+xml': ['.kml'],
      'application/gpx+xml': ['.gpx'],
      'application/zip': ['.zip'],
    },
  });

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={modalStyle}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography variant="h6">Upload your area of interest</Typography>
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
        <Divider sx={{ mb: 2, mt: 2 }} />

        <Box
          {...getRootProps()}
          sx={{
            border: '1px dashed grey',
            padding: 2,
            marginTop: 2,
            cursor: 'pointer',
            minHeight: '320px',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <Typography>Drop the file here ...</Typography>
          ) : (
            <Box
              sx={{
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography>Drag 'n' drop a file here, or click to select a file</Typography>
              <Button variant="contained" sx={{ m: 3 }} size="large">
                Upload File
              </Button>
              <Typography variant="body1">
                <b>The supported file types are:</b>
              </Typography>
              <Typography variant="body2" sx={{ m: 1, textAlign: 'center' }}>
                <b>Geojson</b> <i>(.geojson)</i>,<b>Shapefile</b>{' '}
                <i>(.zip - must contain a .shp, .prj, .dbf and .shx file in the zipped folder),</i>
                <br />
                <b>Google Earth</b> <i>(.kmz, .kml)</i>, and <b>GPS Exchange Format</b> <i>(.gpx)</i>
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Modal>
  );
};
