import { Box, IconButton, Tooltip, styled } from '@mui/material';
import mapboxgl from 'mapbox-gl';
import { Root, createRoot } from 'react-dom/client';
import mapStyleDarkIcon from './assets/map-style-dark.png';
import mapStyleLightIcon from './assets/map-style-light.png';
import mapStyleSatelliteIcon from './assets/map-style-satellite.png';
import mapStyleStreetsIcon from './assets/map-style-streets.png';
import mapStyleSatelliteStreetsIcon from './assets/map-style-satellite-street.png';

export const enum MAP_STYLES {
  SATELLITE_STREETS = 'mapbox://styles/mapbox/satellite-streets-v12',
  SATELLITE = 'mapbox://styles/mapbox/satellite-v9',
  STREETS = 'mapbox://styles/mapbox/streets-v11',
  LIGHT = 'mapbox://styles/mapbox/light-v10',
  DARK = 'mapbox://styles/mapbox/dark-v10',
}

export interface MapStyleProps {
  onChangeStyle?: (style: MAP_STYLES) => void;
}

export class MapStyleSwitchControl implements mapboxgl.IControl {
  private container: HTMLElement;
  private root: Root;
  private map: mapboxgl.Map;

  constructor() {
    this.container = document.createElement('div');
    this.container.className = 'mapboxgl-ctrl';
    this.root = createRoot(this.container);

    this.render();
  }

  onAdd(map: mapboxgl.Map): HTMLElement {
    this.map = map;
    return this.container;
  }

  onRemove(): void {
    this.container.parentNode.removeChild(this.container);
  }

  getDefaultPosition(): string {
    return 'bottom-left';
  }

  changeStyle(style: MAP_STYLES) {
    this.map.setStyle(style);
    this.render();
  }

  render() {
    this.root.render(<MapStyleSwitchComponent onChangeStyle={(style) => this.changeStyle(style)} />);
  }
}

const StyleIcon = styled('img')({
  width: '24px',
  height: '24px',
  boxSizing: 'border-box',
  border: '2px solid gold',
  borderRadius: '4px',
});

const ControlContainer = styled(Box)({
  backgroundColor: 'white',
  borderRadius: '4px',
  display: 'flex',
  justifyContent: 'space-evenly',
  width: '140px',
  padding: '4px 0 4px 0',
});

const StyleButton = styled(IconButton)({
  padding: 0,
});

export const MapStyleSwitchComponent: React.FC<MapStyleProps> = ({ onChangeStyle }) => {
  const handleChangeStyle = (style: MAP_STYLES) => {
    onChangeStyle(style);
  };

  return (
    <ControlContainer id="map-style-switch-control">
      <StyleButton onClick={() => handleChangeStyle(MAP_STYLES.SATELLITE_STREETS)}>
        <Tooltip title="Satellite View With Streets Name" placement="top">
          <StyleIcon src={mapStyleSatelliteStreetsIcon} alt="Satellite Streets" />
        </Tooltip>
      </StyleButton>
      <StyleButton onClick={() => handleChangeStyle(MAP_STYLES.SATELLITE)}>
        <Tooltip title="Satellite View" placement="top">
          <StyleIcon src={mapStyleSatelliteIcon} alt="Satellite" />
        </Tooltip>
      </StyleButton>
      <StyleButton onClick={() => handleChangeStyle(MAP_STYLES.STREETS)}>
        <Tooltip title="Streets View" placement="top">
          <StyleIcon src={mapStyleStreetsIcon} alt="Streets" />
        </Tooltip>
      </StyleButton>
      <StyleButton onClick={() => handleChangeStyle(MAP_STYLES.LIGHT)}>
        <Tooltip title="Light View" placement="top">
          <StyleIcon src={mapStyleLightIcon} alt="Light" />
        </Tooltip>
      </StyleButton>
      <StyleButton onClick={() => handleChangeStyle(MAP_STYLES.DARK)}>
        <Tooltip title="Dark View" placement="top">
          <StyleIcon src={mapStyleDarkIcon} alt="Dark" />
        </Tooltip>
      </StyleButton>
    </ControlContainer>
  );
};
