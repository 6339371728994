import { Box, Card, Divider, Typography } from '@mui/material';
import React from 'react';
import { FindOutMoreOverviewFragment } from './overview.fragment';
import TreeSurveys from './assets/tree-surveys.png';

export const TreeSurveysFragment = () => {
  return (
    <Box
      className="find-out-more-content"
      sx={{
        display: 'flex',
        direction: 'row',
        m: 'var(--content-spacing)',
        pl: 'var(--content-spacing)',
        pr: 'var(--content-spacing)',
      }}
    >
      <Box sx={{ m: '0 var(--content-spacing) 0 0' }}>
        <Typography variant="h4" sx={{ p: 0, fontWeight: 'bold', color: '#545454' }}>
          Tree Surveys
        </Typography>
        <Divider sx={{ m: 'var(--content-spacing-narrow) 0 var(--content-spacing-narrow) 0' }} />
        <Typography
          variant="body1"
          sx={{ m: 'var(--content-spacing-narrow) 0 var(--content-spacing-narrow) 0' }}
        >
          <i>
            Trees are nature’s solution to climate change. They absorb carbon and pollution, cool air
            temperatures and enhance overall well-being for residents.
          </i>
        </Typography>
        <Typography sx={{ mt: 2 }}>
          <b>What is a Gentian Tree Survey?</b>
        </Typography>
        <Typography
          variant="body1"
          sx={{ m: 'var(--content-spacing-narrow) 0 var(--content-spacing-narrow) 0' }}
        >
          A Gentian Tree Survey identifies and maps all the trees in an area. Tree counts and canopy cover can
          be provided per borough, ward or type of greenspace (e.g. public parks or residential gardens). Get
          in touch if you would like additional information about tree species or tree health.
        </Typography>
        <Typography sx={{ mt: 2 }}>
          <b>What does a Gentian Tree Survey include? </b>
        </Typography>
        <Typography
          variant="body1"
          sx={{ m: 'var(--content-spacing-narrow) 0 var(--content-spacing-narrow) 0' }}
        >
          Results are provided in the form of digital maps and a report.
        </Typography>
        <Card
          sx={{
            p: 'var(--content-spacing-narrow)',
            maxWidth: '800px',
            mb: 'var(--content-spacing)',
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: 'bold',
              fontSize: '14px',
              color: '#545454',
            }}
          >
            Example Tree Survey
          </Typography>
          <img src={TreeSurveys} style={{ width: '100%' }} />
        </Card>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 'bold',
            fontSize: '18px',
            color: '#545454',
            mt: 'var(--content-spacing)',
            mb: 'var(--content-spacing)',
          }}
        >
          Products Overview
        </Typography>
        <FindOutMoreOverviewFragment />
      </Box>
    </Box>
  );
};
