import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Typography,
  Switch,
  Tabs,
  Tab,
  Slider,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from '@mui/material';
import { IResultLayer } from '../../core/interfaces/result.interface';
import { ChartPattern } from '../../core/enums/chart-pattern.enum';
import { MuiColorInput } from 'mui-color-input';

export interface DetailsConfigurationProps {
  layer: IResultLayer;
  onLayerEdit: (layer: IResultLayer) => void;
}

export const DetailsConfiguration: React.FC<DetailsConfigurationProps> = ({ layer, onLayerEdit }) => {
  const [layerData, setLayerData] = useState<IResultLayer>(layer);
  const [selectedClassIndex, setSelectedClassIndex] = useState<number>(0);

  const [timeoutToken, setTimeoutToken] = useState<NodeJS.Timeout>();

  useEffect(() => {
    if (timeoutToken) clearTimeout(timeoutToken);

    const token = setTimeout(() => {
      onLayerEdit(layerData);
      setTimeoutToken(undefined);
    }, 300);

    setTimeoutToken(token);
  }, [layerData, onLayerEdit]);

  return (
    <Box display="flex" flexDirection="column" sx={{ width: '100%' }}>
      <Typography variant="body2" sx={{ mt: 1, mb: 2 }}>
        Layer Configuration
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <label htmlFor="chart-visibility">Display in the Layers Panel</label>
        <Switch
          checked={layerData.details.visible}
          onChange={(e) => {
            layerData.details.visible = e.target.checked;
            setLayerData({ ...layerData });
          }}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <label htmlFor="chart-visibility">Default Enabled</label>
        <Switch
          checked={layerData.details.defaultEnabled !== false} // Default to true if undefined
          onChange={(e) => {
            layerData.details.defaultEnabled = e.target.checked;
            setLayerData({ ...layerData });
          }}
        />
      </Box>

      <Box sx={{ display: 'flex', width: '100%', height: '720px', mt: 2 }}>
        <Tabs
          value={selectedClassIndex}
          orientation="vertical"
          onChange={(_, idx) => setSelectedClassIndex(idx)}
          aria-label="class tabs"
          variant="scrollable"
          sx={{ borderRight: 1, borderColor: 'divider' }}
        >
          {layerData.details.classes.map((classItem, index) => (
            <Tab key={`tab_class_${index}`} label={classItem.displayName} sx={{ fontSize: '10px' }} />
          ))}
        </Tabs>

        {layerData.details.classes.map((classItem, layerIndex) => (
          <Box
            key={`box_class_${layerIndex}`}
            display={layerIndex === selectedClassIndex ? 'flex' : 'none'}
            justifyContent="space-evenly"
            alignContent="flex-start"
            flexDirection="column"
            sx={{ ml: 2 }}
          >
            <TextField
              label="Display Name"
              value={classItem.displayName}
              fullWidth
              onChange={(e) => {
                classItem.displayName = e.target.value;
                setLayerData({ ...layerData });
              }}
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <label htmlFor="defaultEnabled">Default Enabled</label>
              <Switch
                checked={classItem.defaultEnabled !== false} // Default to true if undefined
                onChange={(e) => {
                  classItem.defaultEnabled = e.target.checked;
                  setLayerData({ ...layerData });
                }}
              />
            </Box>
            <MuiColorInput
              name="fillColor"
              value={classItem.fillColor}
              isAlphaHidden={true}
              format="hex"
              onChange={(color) => {
                classItem.fillColor = color;
                setLayerData({ ...layerData });
              }}
              label="Fill Color"
            />

            <Box>
              <Typography gutterBottom sx={{ fontSize: '12px' }}>
                Fill Transparency ({classItem.fillTransparency})
              </Typography>
              <Slider
                value={classItem.fillTransparency}
                onChange={(e, value) => {
                  classItem.fillTransparency = value as number;
                  setLayerData({ ...layerData });
                }}
                step={0.1}
                min={0}
                max={1}
                valueLabelDisplay="auto"
              />
            </Box>

            <FormControl fullWidth>
              <InputLabel id={`fill-pattern-select-label-${layerIndex}`}>Fill Pattern</InputLabel>
              <Select
                labelId={`fill-pattern-select-label-${layerIndex}`}
                id={`fill-pattern-select-${layerIndex}`}
                value={classItem.fillPattern}
                label="Fill Pattern"
                onChange={(e) => {
                  classItem.fillPattern = e.target.value as ChartPattern;
                  setLayerData({ ...layerData });
                }}
              >
                {Object.keys(ChartPattern).map((key) => (
                  <MenuItem key={key} value={ChartPattern[key]}>
                    {ChartPattern[key]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <MuiColorInput
              name="edgeColor"
              value={classItem.edgeColor}
              isAlphaHidden={true}
              format="hex"
              fullWidth
              onChange={(color) => {
                classItem.edgeColor = color;
                setLayerData({ ...layerData });
              }}
              label="Edge Color"
            />

            <Box>
              <Typography gutterBottom sx={{ fontSize: '12px' }}>
                Edge Transparency ({classItem.edgeTransparency})
              </Typography>
              <Slider
                value={classItem.edgeTransparency}
                onChange={(e, value) => {
                  classItem.edgeTransparency = value as number;
                  setLayerData({ ...layerData });
                }}
                step={0.1}
                min={0}
                max={1}
                valueLabelDisplay="auto"
              />
            </Box>

            <Box>
              <Typography gutterBottom sx={{ fontSize: '12px' }}>
                Edge Thickness ({classItem.edgeThickness})
              </Typography>
              <Slider
                value={classItem.edgeThickness}
                onChange={(e, value) => {
                  classItem.edgeThickness = value as number;
                  setLayerData({ ...layerData });
                }}
                step={1}
                min={0}
                max={5}
                valueLabelDisplay="auto"
              />
            </Box>

            {classItem.infoValues.map((column, i) => (
              <Box
                key={`box_infoValues_${layerIndex}_${i}`}
                display="flex"
                flexDirection="row"
                alignItems="center"
              >
                <TextField
                  label={layerData.details.infoColumns[i].displayName}
                  value={column}
                  fullWidth
                  onChange={(e) => {
                    classItem.infoValues[i] = e.target.value;
                    layerData.details.classes[layerIndex] = classItem;
                    setLayerData({ ...layerData });
                  }}
                />
              </Box>
            ))}
          </Box>
        ))}
      </Box>
    </Box>
  );
};
