import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { RestartAlt } from '@mui/icons-material';
import { Box, IconButton, Typography, styled } from '@mui/material';
import { Root, createRoot } from 'react-dom/client';
import { ConfirmationDialog } from '../ConfirmationDialog';
import React from 'react';
import { AreaCalculationControl } from './AreaCalculationControl';

export class ResetControl implements mapboxgl.IControl {
  private container: HTMLElement;
  private root: Root;
  private map: mapboxgl.Map;
  private draw: MapboxDraw;
  private area: AreaCalculationControl;

  constructor(draw: MapboxDraw, area: AreaCalculationControl) {
    this.container = document.createElement('div');
    this.container.className = 'mapboxgl-ctrl';
    this.root = createRoot(this.container);
    this.draw = draw;
    this.area = area;

    this.render();
  }

  render() {
    this.root.render(<ResetDrawComponent draw={this.draw} area={this.area} />);
  }

  onAdd(map) {
    this.map = map;
    return this.container;
  }

  onRemove() {
    this.container.parentNode.removeChild(this.container);
    this.map = undefined;
  }
}

const ControlContainer = styled(Box)({
  backgroundColor: 'white',
  borderRadius: '4px',
});

export const ResetDrawComponent: React.FC<{ draw: MapboxDraw; area: AreaCalculationControl }> = ({
  draw,
  area,
}) => {
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);

  const handleConfirm = () => {
    draw.deleteAll();
    area.update(draw.getAll());
    setConfirmDialogOpen(false);
  };

  return (
    <ControlContainer id="map-reset-control">
      <IconButton
        title="Reset"
        size="small"
        onClick={() => {
          setConfirmDialogOpen(true);
        }}
        sx={{
          '& .MuiSvgIcon-root': { fontSize: '20px', color: 'black' },
        }}
      >
        <RestartAlt />
      </IconButton>
      <ConfirmationDialog
        title="Confirmation"
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        onAgree={() => handleConfirm()}
      >
        <Typography>
          You are about to clear the map. Any changes will be lost. Do you wish to continue?
        </Typography>
      </ConfirmationDialog>
    </ControlContainer>
  );
};
