import React from 'react';
import { Tabs, Tab, Box, IconButton, Modal, DialogContent, Divider, Typography } from '@mui/material';
import { PieChartComponent } from '../PieChart';
import { Close, Fullscreen } from '@mui/icons-material';
import { IResultChart } from '../../core/interfaces/result.interface';

interface ChartTabsProps {
  chartData: IResultChart[];
}

const ChartTabs: React.FC<ChartTabsProps> = ({ chartData }) => {
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
  const [showChartModal, setShowChartModal] = React.useState(false);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTabIndex(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Tabs value={selectedTabIndex} onChange={handleTabChange} variant="scrollable">
        {chartData.map((tab) => (
          <Tab label={tab.displayName} key={`chart-tab_tab-panel_${tab.displayName}`} />
        ))}
      </Tabs>
      {chartData[selectedTabIndex] && (
        <Box sx={{ display: 'flex', flexDirection: 'column', position: 'relative', py: 1 }}>
          <Box
            sx={{
              width: '100%',
              justifyContent: 'flex-end',
              display: 'flex',
              position: 'absolute',
              top: '4px',
              right: '4px',
            }}
          >
            <IconButton
              onClick={() => {
                setShowChartModal(true);
              }}
            >
              <Fullscreen />
            </IconButton>
          </Box>
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Box sx={{ width: '128px', height: '128px' }}>
              <PieChartComponent chart={chartData[selectedTabIndex]} />
            </Box>
          </Box>
        </Box>
      )}
      <Modal
        open={showChartModal}
        onClose={() => {
          setShowChartModal(false);
        }}
      >
        <DialogContent
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '70vw',
            height: '70vh',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 2,
            outline: 'none',
            borderRadius: 2,
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              overflow: 'hidden',
            }}
          >
            <Typography variant="h6">{chartData[selectedTabIndex]?.displayName}</Typography>
            <IconButton
              onClick={() => {
                setShowChartModal(false);
              }}
            >
              <Close />
            </IconButton>
          </Box>
          <Divider sx={{ mb: 2, mt: 2 }} />
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              minHeight: '80%',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                height: '55vh',
                width: '100%',
                overflow: 'hidden',
              }}
            >
              <PieChartComponent chart={chartData[selectedTabIndex]} displayLegend />
            </Box>
          </Box>
        </DialogContent>
      </Modal>
    </Box>
  );
};

export default ChartTabs;
