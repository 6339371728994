function hexToRGB(hex: string, alpha: number): string {
  // Remove the hash at the front if it's there
  hex = hex.replace(/^#/, '');

  // If the string is in the short format, convert it to the long format
  if (hex.length < 6) {
    hex = hex
      .split('')
      .map((char) => char + char)
      .join('');
  }

  // Convert the hex string to RGB values
  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);

  return `rgb(${r}, ${g}, ${b}, ${alpha})`;
}

function generateRandomRGBColor(): string {
  // Generate a random integer between 0 and 255 for each color component
  const red = Math.floor(Math.random() * 256);
  const green = Math.floor(Math.random() * 256);
  const blue = Math.floor(Math.random() * 256);

  // Combine them into the RGB color string
  return `rgb(${red}, ${green}, ${blue})`;
}

function generateRandomHexColor(): string {
  // Generate a random integer between 0 and 255 for each color component
  const red = Math.floor(Math.random() * 256);
  const green = Math.floor(Math.random() * 256);
  const blue = Math.floor(Math.random() * 256);

  // Convert each number to a hexadecimal string and ensure it's two digits
  const redHex = red.toString(16).padStart(2, '0');
  const greenHex = green.toString(16).padStart(2, '0');
  const blueHex = blue.toString(16).padStart(2, '0');

  // Combine them into the hex color string
  return `#${redHex}${greenHex}${blueHex}`;
}

export const colorUtils = {
  hexToRGB,
  generateRandomRGBColor,
  generateRandomHexColor,
};
