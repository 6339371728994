import {
  Alert,
  Box,
  Button,
  CircularProgress,
  DialogContent,
  IconButton,
  Modal,
  Snackbar,
  Tooltip,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ContentTileComponent } from '../../Components/ContentTitle';
import { MapComponent } from '../../Components/Map';
import { Res } from '../../resources';
import React, { useEffect, useState } from 'react';

import * as turf from '@turf/turf';

import { LoadAOIComponent } from '../../Components/LoadAOI';
import { DownloadAOIComponent } from '../../Components/DownloadAIO';
import { CheckoutComponent } from '../../Components/Checkout';
import { CheckoutSuccessComponent } from '../../Components/Checkout/CheckoutSuccess';
import { IAOIInfo } from '../../core/interfaces/aoi-info.interface';
import { OnboardingComponent } from '../../Components/Onboarding';
import { useAuth0 } from '@auth0/auth0-react';
import useGentian from '../../core/hookies/gentian.hook';
import { Help } from '@mui/icons-material';
import { ConfirmationDialog } from '../../Components/ConfirmationDialog';
import { useLocation } from 'react-router-dom';
import { datadogLogs } from '@datadog/browser-logs';

const ActionsPanel = styled(Box)({
  display: 'flex',
  padding: '8px var(--content-spacing) 0 var(--content-spacing)',
  justifyContent: 'space-between',
  flexDirection: 'row',
});

const AOIActions = styled(Box)({
  display: 'flex',
  width: '392px',
});

const DownloadToolTip: React.FC<{ disabled; children }> = ({ disabled, children }) => (
  <Tooltip
    title={
      !disabled
        ? 'Draw an area of interest on the map to enable the download feature'
        : 'Download your AOI as a GeoJSON file'
    }
    children={children}
  />
);

const CheckoutToolTip: React.FC<{ disabled; children }> = ({ disabled, children }) => (
  <Tooltip
    title={
      !disabled
        ? 'Draw an area of interest on the map to continue to the product selection'
        : 'Select the products you are interested in'
    }
    children={children}
  />
);

export const SurveyRequestPage = () => {
  const location = useLocation();
  const { AOI } = location.state || {};

  const { isLoading: isUserLoading } = useAuth0();

  const [drawnAOI, setDrawnAOI] = React.useState<GeoJSON.FeatureCollection | undefined>(AOI);
  const [loadedAOI, setLoadedAOI] = React.useState<GeoJSON.FeatureCollection | undefined>(AOI);
  const [aoiInfo, setAOIInfo] = React.useState<IAOIInfo | undefined>(undefined);

  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);
  const [checkoutModalOpen, setCheckoutModalOpen] = React.useState(false);
  const [onboardingModalOpen, setOnboardingModalOpen] = React.useState(false);
  const [projectSubmitted, setProjectSubmitted] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');

  const [snackbarType, setSnackbarType] = React.useState<
    'success' | 'error' | 'warning' | 'info' | undefined
  >('success');

  const handleLoadAOIError = (error: Error) => {
    setSnackbarMessage(error.message);
    setSnackbarType('error');
    setSnackbarOpen(true);
  };

  const { getAccount, getProducts } = useGentian();
  const { data: productsData, isLoading: isLoadingProducts } = getProducts();
  const { data: accountData, isLoading: isLoadingAccount } = getAccount();
  const productList = React.useRef(productsData);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [showToolTip, setShowToolTip] = useState(localStorage.getItem('dismiss-close-shape-tip') !== 'true');

  useEffect(() => {
    if (accountData?.data && !accountData?.data?.user_metadata?.termsAgreed) {
      setOnboardingModalOpen(true);
    }
  }, [accountData]);

  useEffect(() => {
    if (productsData) productList.current = productsData;
  }, [productsData]);

  useEffect(() => {
    if (AOI) {
      handleLoadAOI(AOI);
    }
  }, [AOI]);

  const handleLoadAOI = (geojson: GeoJSON.FeatureCollection) => {
    setDrawnAOI(undefined);
    setLoadedAOI(undefined);
    setAOIInfo(undefined);

    setTimeout(() => {
      setLoadedAOI(geojson);
      setDrawnAOI(geojson);
    }, 0);

    setSnackbarMessage('AOI loaded successfully');
    setSnackbarType('success');
    setSnackbarOpen(true);
  };

  const handleContinueToCheckout = async () => {
    setAOIInfo(await getAOIInfo());
    setCheckoutModalOpen(true);
  };

  const generateMapThumbnail = async (tolerance = 0.0001): Promise<string> => {
    if (tolerance > 1) {
      datadogLogs.logger.error('Could not generate map thumbnail', {
        name: 'generateMapThumbnail',
        tolerance,
      });
      throw new Error('Could not generate map thumbnail');
    }

    if (!drawnAOI) return;

    return new Promise(async (resolve, reject) => {
      const geodata = turf.featureCollection(
        drawnAOI.features
          .map((f) => {
            delete f.bbox;
            delete f.id;
            delete f.properties;
            return f;
          })
          .map((f) => {
            return turf.simplify(f as any, { tolerance, highQuality: true });
          }),
      );
      const encodedGeojson = encodeURIComponent(JSON.stringify(geodata));
      const uri = `https://api.mapbox.com/styles/v1/mapbox/streets-v12/static/geojson(${encodedGeojson})/auto/500x300?access_token=${Res.components.maps.MAPBOX_TOKEN}`;

      if (uri.length > 8192) resolve(await generateMapThumbnail(tolerance * 10));

      try {
        const response = await fetch(uri);
        const blob = await response.blob();
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64data = reader.result;
          resolve(base64data.toString());
        };
        reader.readAsDataURL(blob);
      } catch (error) {
        datadogLogs.logger.error('Error generating map thumbnail', {
          error,
          name: 'generateMapThumbnail',
        });
        datadogLogs.logger.error('Error generating map thumbnail', {
          error,
          name: 'generateMapThumbnail',
        });
        console.error('Error generating map thumbnail:', error);
        reject(error);
      }
    });
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const getAOIInfo = async (): Promise<IAOIInfo> => {
    let thumbnail;
    try {
      thumbnail = await generateMapThumbnail();
    } catch (error) {
      datadogLogs.logger.error('Error generating map thumbnail', {
        error,
        name: 'getAOIInfo',
      });
      setSnackbarMessage((error as Error).message);
      setSnackbarType('error');
      setSnackbarOpen(true);
    }

    return {
      thumbnail,
      TotalArea: drawnAOI ? Math.round((turf.area(drawnAOI) / 10000) * 100) / 100 : 0, //ha
      shape: drawnAOI,
      units: 'ha',
    };
  };

  const handleConfirmEnterTutorialMode = () => {
    setConfirmDialogOpen(false);
    localStorage.setItem('request-survey-map-onboarding-done', 'false');
    localStorage.setItem('dismiss-close-shape-tip', 'false');
    window.location.reload();
  };

  return isUserLoading === true || isLoadingAccount || isLoadingProducts ? (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <Box>
      <ContentTileComponent className="hide-on-fullscreen">
        <Box sx={{ display: 'flex', justifyContent: 'space-between', p: '0 16px 0 16px' }}>
          Request a new site survey
          {!isMobile && showToolTip && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                backgroundColor: 'var(--gentian-tertiary)',
                py: '4px',
                px: '16px',
                borderRadius: '4px',
                height: '16px',
                width: '50%',
              }}
            >
              <Typography
                sx={{
                  fontSize: '10px',
                }}
              >
                Press enter or double click to close your polygon
              </Typography>
              <Typography
                sx={{
                  fontSize: '10px',
                  color: '#555',
                  direction: 'rtl',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  localStorage.setItem('dismiss-close-shape-tip', 'true');
                  setShowToolTip(false);
                }}
              >
                dismiss
              </Typography>
            </Box>
          )}
          <IconButton
            size="small"
            onClick={() => {
              setConfirmDialogOpen(true);
            }}
          >
            <Tooltip title="Show tutorial">
              <Help />
            </Tooltip>
          </IconButton>
          <ConfirmationDialog
            title="Confirmation"
            open={confirmDialogOpen}
            onClose={() => setConfirmDialogOpen(false)}
            onAgree={() => handleConfirmEnterTutorialMode()}
          >
            <Typography>
              You are about to enter tutorial mode. Any changes will be lost. Do you wish to continue?
            </Typography>
          </ConfirmationDialog>
        </Box>
      </ContentTileComponent>
      <MapComponent
        initialShape={loadedAOI}
        accessToken={Res.components.maps.MAPBOX_TOKEN}
        onShapeDrawn={(shape) => {
          setLoadedAOI(shape);
          setDrawnAOI(shape);
          setAOIInfo(undefined);
        }}
        sx={{
          left: 'var(--content-spacing)',
          width: 'calc(100vw - var(--content-spacing) * 2)',
          height: 'calc(100vh - var(--header-height) - var(--footer-height) - 128px)',
        }}
      />
      <ActionsPanel className="hide-on-fullscreen">
        <AOIActions>
          <LoadAOIComponent
            id="map-load-aoi-component"
            onLoadAOI={handleLoadAOI}
            onError={handleLoadAOIError}
          />
          <DownloadToolTip disabled={!drawnAOI}>
            <span style={{ marginLeft: '8px' }} id="map-download-aoi-container">
              <DownloadAOIComponent AOI={drawnAOI} disabled={!drawnAOI} />
            </span>
          </DownloadToolTip>
        </AOIActions>
        <CheckoutToolTip disabled={!drawnAOI}>
          <span>
            <Button
              id="map-checkout-button"
              variant="contained"
              disabled={!drawnAOI}
              onClick={handleContinueToCheckout}
            >
              Continue
            </Button>
          </span>
        </CheckoutToolTip>
        <OnboardingComponent
          open={onboardingModalOpen}
          handleClose={(_, reason) => {
            if (reason && reason == 'backdropClick') return;
            setOnboardingModalOpen(false);
          }}
        />
        <Modal
          open={checkoutModalOpen}
          onClose={() => {
            setCheckoutModalOpen(false);
            setProjectSubmitted(false);
          }}
        >
          <DialogContent>
            {!projectSubmitted ? (
              <CheckoutComponent
                products={productList.current}
                AOIInfo={aoiInfo}
                onClose={() => setCheckoutModalOpen(false)}
                onSuccess={() => setProjectSubmitted(true)}
              />
            ) : (
              <CheckoutSuccessComponent
                onClose={() => {
                  setProjectSubmitted(false);
                  setCheckoutModalOpen(false);
                }}
              >
                <DownloadAOIComponent AOI={drawnAOI} />
                <Typography
                  fontStyle="italic"
                  color="gray"
                  sx={{ padding: 'var(--content-spacing)' }}
                  textAlign="center"
                >
                  Download a copy of your survey boundary to use in future requests. A copy of this file is
                  also attached to your email confirmation. Remember to check your spam folder.
                </Typography>
              </CheckoutSuccessComponent>
            )}
          </DialogContent>
        </Modal>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={snackbarType === 'success' ? 2000 : 6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbarType} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </ActionsPanel>
    </Box>
  );
};
