import { Typography, Box, TextField, Select, MenuItem, Switch, Divider } from '@mui/material';
import { IResultLayer } from '../../core/interfaces/result.interface';
import { useEffect, useState } from 'react';

export interface DataSourceConfigurationProps {
  layer: IResultLayer;
  data: Record<string, any>[];
  onLabelSourceChange: (columnName: string) => void;
  onDataSourceChange: (index: number, columnName: string) => void;
  onDisplayInLayerPanelChange: (index: number, visible: boolean) => void;
  onDisplayInInspectorChange: (displayName: string, visible: boolean) => void;
  onDisplayNameChange: (index: number, displayName: string) => void;
}

export const DataSourceConfiguration: React.FC<DataSourceConfigurationProps> = ({
  layer,
  data,
  onLabelSourceChange,
  onDataSourceChange,
  onDisplayInLayerPanelChange,
  onDisplayInInspectorChange,
  onDisplayNameChange,
}) => {
  const [layerData] = useState<IResultLayer>(layer);
  const [columns, setColumns] = useState<string[]>([]);
  const [infoColsConfig, setInfoColsConfig] = useState<Record<string, any>[]>([]);

  useEffect(() => {
    const columns = Object.keys(data.reduce((acc, cur) => ({ ...acc, ...cur }), {})).sort();
    setColumns(columns);
  }, [data]);

  useEffect(() => {
    infoColsConfig.splice(0, infoColsConfig.length);
    infoColsConfig.push(...layerData.details.infoColumns);

    setInfoColsConfig([...infoColsConfig]);
  }, [layerData]);

  return (
    <Box display="flex" flexDirection="column" sx={{ width: '100%' }}>
      <Typography variant="body2" sx={{ mt: 1, mb: 2 }}>
        Data Source Configuration
      </Typography>

      {!!columns.length && (
        <Box>
          <Typography sx={{ fontSize: '12px' }}>Select a column for the Labels (required)</Typography>
          <Select
            sx={{ mb: 2 }}
            id="column"
            value={layerData.details.columnName}
            fullWidth
            onChange={(e) => onLabelSourceChange(e.target.value as string)}
            displayEmpty
          >
            {columns.map((col) => (
              <MenuItem key={col} value={col}>
                {col}
              </MenuItem>
            ))}
          </Select>
          <Divider sx={{ mb: 1 }} />
        </Box>
      )}

      {!!layerData.details.columnName && (
        <Typography variant="body2" sx={{ mt: 1, mb: 2 }}>
          Layer Columns Configuration
        </Typography>
      )}
      {!!layerData.details.columnName &&
        infoColsConfig?.map((infoCol, infoColIndex) => (
          <Box key={`infoCol_${infoColIndex}`} display="flex" flexDirection="column" alignItems="center">
            <TextField
              label={`Info column ${infoColIndex + 1} display name`}
              key={`infoCol_Text_${infoColIndex}`}
              value={infoCol.displayName}
              margin="normal"
              fullWidth
              onChange={(e) => {
                infoCol.displayName = e.target.value;
                infoColsConfig[infoColIndex] = infoCol;
                setInfoColsConfig([...infoColsConfig]);

                onDisplayNameChange(infoColIndex, e.target.value);
              }}
            />
            <Typography sx={{ mt: 1, width: '100%', fontSize: '12px' }}>
              Select a columns with the values for {infoCol.displayName} (optional)
            </Typography>
            <Select
              fullWidth
              value={infoCol.dataColumn || ''}
              onChange={(e) => {
                infoCol.dataColumn = e.target.value as string;
                infoColsConfig[infoColIndex] = infoCol;
                setInfoColsConfig([...infoColsConfig]);

                onDataSourceChange(infoColIndex, e.target.value as string);
              }}
              displayEmpty
            >
              {columns
                .filter((c) => c !== layerData.details.columnName)
                .map((col) => (
                  <MenuItem key={col} value={col}>
                    {col}
                  </MenuItem>
                ))}
            </Select>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              sx={{ width: '100%' }}
              justifyContent="space-between"
            >
              <Typography style={{ fontSize: '12px' }}>Display column in the layer panel</Typography>
              <Switch
                key={`infoCol_Switch${infoColIndex}`}
                id={`info-column-visibility-${infoColIndex}`}
                checked={infoCol.visible}
                onChange={(e) => {
                  infoCol.visible = e.target.checked;
                  infoColsConfig[infoColIndex] = infoCol;
                  setInfoColsConfig([...infoColsConfig]);

                  onDisplayInLayerPanelChange(infoColIndex, e.target.checked);
                }}
              />
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              sx={{ width: '100%' }}
              justifyContent="space-between"
            >
              <Typography style={{ fontSize: '12px' }}>Display column in the inspector tool</Typography>
              <Switch
                key={`inspectorCol_Switch${infoColIndex}`}
                id={`inspector-column-visibility-${infoColIndex}`}
                checked={layerData.inspector.columnsToDisplay.includes(infoCol.displayName)}
                onChange={(e) => {
                  onDisplayInInspectorChange(infoCol.displayName, e.target.checked);
                }}
              />
            </Box>
            <Divider sx={{ mt: 1, mb: 1, width: '100%' }} />
          </Box>
        ))}
    </Box>
  );
};
