import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Checkbox, Box, Typography } from '@mui/material';
import { IResultLayer } from '../../core/interfaces/result.interface';
import { colorUtils } from '../../Utils/color.utils';

interface LayerDetailsProps {
  layer: IResultLayer;
  onCheckboxChange: (checked: boolean, classItem: any) => void;
}

const getId = (resultId: string, columnName: string, category: string) => {
  return `${resultId}_${columnName}_${category.toString().replace(/\s/g, '-')}`;
};

const LayerDetails: React.FC<LayerDetailsProps> = ({ layer, onCheckboxChange }) => {
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, classItem: any) => {
    const id = event.target.id;
    classItem.defaultEnabled = event.target.checked;
    onCheckboxChange?.(event.target.checked, { id, classItem });
  };

  return (
    <Table sx={{ width: '100%' }}>
      <TableHead>
        <TableRow sx={{ p: 0, m: 0 }}>
          <TableCell sx={{ p: 0, m: 0 }}>&nbsp;</TableCell>
          <TableCell sx={{ p: 0, m: 0 }}>&nbsp;</TableCell>
          <TableCell sx={{ p: 0, m: 0 }}>&nbsp;</TableCell>
          {layer.details.infoColumns.map(
            (infoColumn, index) =>
              infoColumn.visible && (
                <TableCell sx={{ p: 0, m: 0, textAlign: 'center' }} key={index}>
                  <Typography sx={{ fontSize: '10px' }}>{infoColumn.displayName}</Typography>
                </TableCell>
              ),
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {[...layer.details.classes]
          .sort((a, b) => (+(a.infoValues[0] || 0) < +(b.infoValues[0] || 0) ? 1 : -1))
          .map((classItem, classIndex) => (
            <TableRow key={classIndex} sx={{ p: 0, m: 0 }}>
              <TableCell sx={{ p: 0, m: 0, textAlign: 'center' }}>
                <Checkbox
                  id={getId(layer.resultId, layer.details.columnName, classItem.category)}
                  checked={classItem.defaultEnabled === undefined || classItem.defaultEnabled}
                  onChange={(event) => handleCheckboxChange(event, classItem)}
                />
              </TableCell>
              <TableCell sx={{ p: 0, m: 0 }}>
                <Box
                  sx={{
                    width: 12,
                    height: 12,
                    textAlign: 'center',
                    backgroundColor: classItem.fillColor,
                    opacity: 1 - (classItem.fillTransparency || 0),
                    border: `solid ${classItem.edgeThickness}px ${colorUtils.hexToRGB(
                      classItem.edgeColor,
                      1 - (classItem.edgeTransparency || 0),
                    )}`,
                    borderRadius: '50%',
                  }}
                />
              </TableCell>
              <TableCell>
                <Typography sx={{ fontSize: '10px', textTransform: 'capitalize' }}>
                  {classItem.displayName}
                </Typography>
              </TableCell>
              {layer.details.infoColumns.map(
                (infoColumn, columnIndex) =>
                  infoColumn.visible && (
                    <TableCell key={columnIndex}>
                      <Typography sx={{ fontSize: '10px', textAlign: 'center' }}>
                        {(+(classItem.infoValues[columnIndex] || 0)).toLocaleString('en-UK', {
                          maximumFractionDigits: 2,
                        })}
                      </Typography>
                    </TableCell>
                  ),
              )}
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};

export default LayerDetails;
