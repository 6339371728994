// Adapted from https://www.npmjs.com/package/gbify-geojson
// Credits: https://www.npmjs.com/~robmurray17

import proj4 from 'proj4';

const OSGB36 = proj4(
  '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy' +
    '+towgs84=446.448,-125.157,542.060,0.1502,0.2470,0.8421,-20.4894 +units=m +no_defs no_defs',
);

function isValidCoord(coord) {
  return coord && coord.length >= 2 && typeof coord[0] === 'number' && typeof coord[1] === 'number';
}

function traverseCoords(coordinates, callback) {
  if (isValidCoord(coordinates)) {
    return callback(coordinates);
  }

  return coordinates.map((coord) => traverseCoords(coord, callback));
}

function traverseGeoJson(geoJson, processLeafNode) {
  if (geoJson.crs) {
    delete geoJson.crs;
  }

  if (geoJson.type === 'Feature') {
    geoJson.geometry = traverseGeoJson(geoJson.geometry, processLeafNode);
  } else if (geoJson.type === 'FeatureCollection') {
    geoJson.features = geoJson.features.map((feature) => traverseGeoJson(feature, processLeafNode));
  } else if (processLeafNode) {
    processLeafNode(geoJson);
  }

  return geoJson;
}

function traverseAndReproject(geoJson, from, to, precision) {
  const reproject = proj4(from, to);

  return traverseGeoJson(geoJson, (leafNode) => {
    leafNode.coordinates = traverseCoords(leafNode.coordinates, (coords) =>
      reproject.forward(coords).map((p) => {
        return 1 * p.toFixed(precision);
      }),
    );
  });
}

const truncatePoint = (point: number) => {
  if (Array.isArray(point)) {
    return point.map((p) => truncatePoint(p));
  } else {
    return +point.toFixed(6);
  }
};

const compress = (geoJson) =>
  geoJson?.features?.map((f) => {
    f.geometry && (f.geometry.bbox = undefined);
    (f.geometry as any)?.coordinates?.forEach((coord) => {
      for (let i = 0; i < coord.length; i++) {
        try {
          if (Array.isArray(coord[i])) {
            coord[i] = coord[i]?.map(truncatePoint);
          } else if (!isNaN(coord[i])) {
            coord[i] = truncatePoint(coord[i]);
          }
        } catch (e) {
          console.warn('Error compressing coordinates', coord, e);
        }
      }
    });
    return f;
  });

export const coordinatesUtils = {
  toOSGB36: (geoJson) => traverseAndReproject(geoJson, proj4.WGS84, OSGB36, 2),
  toWGS84: (geoJson) => traverseAndReproject(geoJson, OSGB36, proj4.WGS84, 6),
  compress,
  truncatePoint,
};
