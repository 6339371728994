import MapboxDraw from '@mapbox/mapbox-gl-draw';

let timeout: NodeJS.Timeout;

const CustomPolygonMode = { ...MapboxDraw.modes.draw_polygon };
const originalClick = CustomPolygonMode.onClick;

CustomPolygonMode.onClick = function (state, e) {
  originalClick.call(this, state, e);
  clearTimeout(timeout);

  timeout = setTimeout(() => {
    if (!state?.polygon?.id) return;

    this.changeMode('direct_select', { featureId: state.polygon.id });
  }, 2000);
};

export default CustomPolygonMode;
