import 'chart.js/auto';
import pattern from 'patternomaly';

import { ChartData } from 'chart.js';
import { FC } from 'react';
import { Pie } from 'react-chartjs-2';
import { colorUtils } from '../../Utils/color.utils';
import { IResultChart } from '../../core/interfaces/result.interface';
import { ChartPattern } from '../../core/enums/chart-pattern.enum';

export interface PieChartProps {
  chart: IResultChart;
  displayLegend?: boolean;
}

const createChartData = (chart: IResultChart): ChartData<'pie', number[], string> => {
  const chartData = [...chart.pieChart].sort((a, b) => (a.percent < b.percent ? 1 : -1));
  return {
    labels: chartData.map((c) => `${c.className}: ${c.percent}%`),
    datasets: [
      {
        data: chartData.map((c) => c.percent),
        borderWidth: 0,
        backgroundColor: chartData.map((c) =>
          c.fillPattern && c.fillPattern !== ChartPattern.None
            ? pattern.draw(
                c.fillPattern as Exclude<ChartPattern, ChartPattern.None | ChartPattern.Undefined>,
                colorUtils.hexToRGB(c.fillColor, 1 - (c.fillTransparency || 0)),
              )
            : colorUtils.hexToRGB(c.fillColor, 1 - (c.fillTransparency || 0)),
        ),
      },
    ],
  };
};

export const PieChartComponent: FC<PieChartProps> = ({ chart, displayLegend }) => {
  if (displayLegend === undefined) {
    displayLegend = false;
  }

  return (
    <Pie
      options={{
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: { position: 'right', display: displayLegend },
        },
      }}
      data={createChartData(chart)}
    />
  );
};
