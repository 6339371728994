export const Res = {
  env: process.env.REACT_APP_ENV || 'local',
  components: {
    maps: {
      MAPBOX_TOKEN:
        'pk.eyJ1IjoiY2hhcmxlc2ZvcnRlcyIsImEiOiJjbG81bmhya2YwMjh1MmtvMzZ4Y3p3NmhmIn0.DRiJTB8yqCfusiZP7n2QSg',
      GOOGLE_TOKEN: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || 'AIzaSyB7f5e74c1hS56KktrDLOSYMy98j7bRZYg',
    },
  },
  api: {
    gentian: {
      BFF_BASE_URL: process.env.REACT_APP_BFF_BASE_URL || 'http://localhost:3001',
    },
    auth0: {
      DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN || 'dev-gentian.eu.auth0.com',
      CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID || 'Pbm099oF4Un5fEA1H7NKmM5MCArrQyUE',
      AUDIENCE: process.env.REACT_APP_AUTH0_AUDIENCE || 'https://api.gop.gentian.com/',
    },
    datadog: {
      CLIENT_TOKEN: process.env.REACT_APP_DATADOG_CLIENT_TOKEN || 'pub7ba59bf4844fec236e8d7f0a705ddfe4',
    },
  },
  feature_flags: {
    ENABLE_DASHBOARD: process.env.REACT_APP_FEATURE_DASHBOARD === 'true' || false,
    ENABLE_VIEW_RESULTS: process.env.REACT_APP_FEATURE_VIEW_RESULTS === 'true' || false,
    ENABLE_RESULT_UPLOAD: process.env.REACT_APP_FEATURE_RESULT_UPLOAD === 'true' || false,
    ENABLE_PRODUCT_MANAGEMENT: process.env.REACT_APP_FEATURE_PRODUCT_MANAGEMENT === 'true' || false,
  },
};
