import { FormControl, TextField, Typography, styled } from '@mui/material';
import { useState } from 'react';

const ProjectForm = styled(FormControl)({
  display: 'flex',
  flexDirection: 'column',
  padding: 'var(--content-spacing-narrow)',
  justifyContent: 'space-evenly',
  alignContent: 'space-evenly',
  alignItems: 'center',
});

const AOIThumbnail = styled('img')({
  maxHeight: '128px',
  maxWidth: '256px',
  border: '1px solid var(--gentian-light-gray)',
  boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.75)',
});

export interface ProjectFormProps {
  AOIInfo;
  showError?: boolean;
  onProjectChange?: (projectName: string, projectDescription: string) => void;
}

export const ProjectFormComponent: React.FC<ProjectFormProps> = ({ AOIInfo, showError, onProjectChange }) => {
  const [projectName, setProjectName] = useState<string>('');
  const [projectDescription, setProjectDescription] = useState<string>('');

  return (
    <ProjectForm>
      {/* <Typography style={{ width: '100%', color: 'var(--gentian-dark-gray)' }}>Survey area</Typography> */}
      <AOIThumbnail src={AOIInfo.thumbnail} />
      <Typography style={{ width: '100%', color: 'var(--gentian-dark-gray)' }}>
        Total Area: {AOIInfo.TotalArea} {AOIInfo.units}
      </Typography>
      <TextField
        id="outlined-basic"
        label="Project Name"
        variant="standard"
        fullWidth
        required
        value={projectName}
        error={showError && !projectName}
        onChange={(e) => {
          setProjectName(e.target.value);
          onProjectChange?.(e.target.value, projectDescription);
        }}
        sx={{
          margin: 'var(--content-spacing-narrow)',
          '& .MuiInput-input': {
            lineHeight: '1.75em',
            height: '1.75em',
          },
        }}
      />
      <TextField
        id="outlined-basic"
        label="Tell Us More"
        variant="standard"
        multiline
        rows={4}
        helperText="Explain your project in more detail."
        fullWidth
        onChange={(e) => {
          setProjectDescription(e.target.value);
          onProjectChange?.(projectName, e.target.value);
        }}
        value={projectDescription}
        sx={{
          margin: 'var(--content-spacing-narrow)',
        }}
      />
    </ProjectForm>
  );
};
