import { datadogLogs } from '@datadog/browser-logs';
import { Box, Typography, CircularProgress } from '@mui/material';
import { ContentTileComponent } from '../../Components/ContentTitle';
import LayersComponent from '../../Components/Layers';
import { MapComponent } from '../../Components/Map';
import { MAP_STYLES } from '../../Components/Map/MapStyleSwitchControl';
import {
  buildSources,
  buildLayersPatterns,
  buildLayersFill,
  buildLayersOutline,
  buildInspectorTool,
  buildLayersImagery,
} from '../../core/builders/layer.builder';
import { IResult } from '../../core/interfaces/result.interface';
import { Res } from '../../resources';
import { useEffect, useRef, useState } from 'react';
import * as turf from '@turf/turf';
import { Map as MapBox } from 'mapbox-gl';
import { popup } from '../SurveyResults';
import { IProject } from '../../core/interfaces/project.interface';

export interface ResultPreviewProps {
  result: IResult;
  project?: IProject;
  isSaving: boolean;
  isPublishing: boolean;
  onMapLoad?: (map: MapBox) => void;
}

export const ResultPreview: React.FC<ResultPreviewProps> = ({
  result,
  isSaving,
  isPublishing,
  project,
  onMapLoad,
}) => {
  const [selectedCategories, setSelectedCategories] = useState<{ id: string; visible: boolean }[]>([]);
  const [mapLayers, setMapLayers] = useState<any>([]);
  const [mapSources, setMapSources] = useState<any>([]);
  const [mapPatterns, setMapPatterns] = useState<any>([]);

  const inspector = useRef(new Map<string, string>());

  useEffect(() => {
    if (
      !!result?.layers?.length &&
      (!!result?.layers[0].details.classes.length || !!result?.layers?.find((l) => !!l.imageryUri))
    ) {
      const sources = result?.layers?.map(buildSources).flat().reverse() || [];
      const layers = [
        ...(buildLayersImagery(sources) || []),
        ...(buildLayersFill(sources, result) || []),
        ...(buildLayersOutline(sources, result) || []),
      ];
      const patterns = buildLayersPatterns(sources, result);

      setMapPatterns(patterns);
      setMapSources([...sources]);
      setMapLayers([...layers.filter((l) => l?.id)]);
    }
  }, [result]);

  useEffect(() => {
    if (result?.layers?.length && mapSources.length) {
      inspector.current = new Map(buildInspectorTool(mapSources, result));
    }
  }, [mapSources]);

  const handleOnMouseEnter = (
    map: mapboxgl.Map,
    sourceId: string,
    e: mapboxgl.MapMouseEvent & mapboxgl.EventData,
  ) => {
    popup.setLngLat(e.lngLat).setHTML(inspector.current.get(sourceId)).addTo(map);
  };

  useEffect(() => {
    if (mapLayers?.length) {
      const allClasses = result?.layers
        .map((layer) =>
          layer.details.classes.map((c) => ({
            id: `${layer.resultId}_${layer.details.columnName}_${c.category.toString().replace(/\s/g, '-')}`,
            visible: layer.details.defaultEnabled !== false && c.defaultEnabled !== false, // Default to true if undefined
          })),
        )
        .flat();
      const selCategories = mapLayers?.map((mapLayer) => ({
        id: mapLayer.id,
        visible: allClasses.find((c) => (mapLayer.id as string).startsWith(c.id))?.visible !== false || false,
      }));
      setSelectedCategories([...selCategories]);
    }
  }, [mapLayers]);

  return (
    <Box
      sx={{
        width: 'calc(100vw - 320px)',
        backgroundColor: 'white',
      }}
    >
      <ContentTileComponent title="Results Preview" />
      {isSaving || isPublishing ? (
        <Box
          sx={{
            width: '100%',
            height: 'calc(100vh - var(--header-height) - 80px - var(--footer-height))',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Typography variant="body1">{isSaving ? 'Saving...' : 'Publishing...'}</Typography>
          <CircularProgress />
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            height: 'calc(100vh - var(--header-height) - 80px - var(--footer-height))',
          }}
        >
          <Box
            sx={{
              overflow: 'auto',
              height: 'calc(100vh - var(--header-height) - 80px - var(--footer-height))',
            }}
          >
            <LayersComponent
              result={result}
              onCheckboxChange={(checked, data) => {
                const selectedCategory = selectedCategories.filter(
                  (c) => c.id === data.id || c.id.startsWith(`${data.id}_`),
                );
                if (selectedCategory?.length) {
                  selectedCategory.forEach((c) => (c.visible = checked));
                } else {
                  selectedCategories.push({ id: data.id, visible: checked });
                }
                setSelectedCategories([...selectedCategories]);
              }}
              onSelect={(selected, layer) => {
                const prefix = `${layer.resultId}_${layer.details.columnName}_`;
                selectedCategories
                  ?.filter((c) => c?.id?.startsWith(prefix))
                  .forEach((c) => (c.visible = selected));
                setSelectedCategories([...selectedCategories]);
              }}
            />
          </Box>

          {!!result?.layers?.length &&
            (!!result?.layers[0].details.classes.length || !!result?.layers?.find((l) => !!l.imageryUri)) && (
              <MapComponent
                accessToken={Res.components.maps.MAPBOX_TOKEN || ''}
                readOnly
                style={MAP_STYLES.SATELLITE}
                hideMapStyleSwitchControl
                sources={mapSources}
                patterns={mapPatterns}
                layers={mapLayers}
                onLoad={(map) => {
                  const sources = result?.layers?.map(buildSources).flat().reverse() || [];
                  const patterns = buildLayersPatterns(sources, result);
                  const layers = [
                    ...(buildLayersImagery(sources) || []),
                    ...(buildLayersFill(sources, result) || []),
                    ...(buildLayersOutline(sources, result) || []),
                  ].filter((l) => l?.id);

                  setMapPatterns(patterns);
                  setMapSources([...sources]);
                  setMapLayers([...layers]);

                  sources?.forEach((source) => {
                    if (map.getSource(source.id)) {
                      map.removeSource(source.id);
                    }
                    map.addSource(source.id, source.data);

                    map.on('mousedown', source.id, (e) => {
                      handleOnMouseEnter(map, source.id, e as any);
                    });

                    map.on('mouseenter', source.id, () => {
                      map.getCanvas().style.cursor = 'pointer';
                    });

                    map.on('mouseleave', source.id, () => {
                      map.getCanvas().style.cursor = '';
                    });
                  });

                  Object.entries(patterns).forEach(([id, pattern]) => {
                    map.loadImage(pattern, (error, image) => {
                      if (error) {
                        datadogLogs.logger.error('Error loading image', {
                          error,
                          name: 'loadImage',
                          id,
                          pattern,
                        });
                        throw error;
                      }
                      if (map.hasImage(id)) {
                        map.removeImage(id);
                      }
                      map.addImage(id, image);
                    });
                  });

                  layers?.forEach((layer) => {
                    if (map.getLayer(layer.id)) {
                      map.removeLayer(layer.id);
                    }
                    map.addLayer(layer);
                  });

                  if (sources.length) {
                    const boundSource =
                      (sources.find((s) => !!(s.data as any)?.data)?.data as any)?.data || project?.AOI;
                    if (boundSource) {
                      map.fitBounds(turf.bbox(boundSource) as any, {
                        padding: 20,
                        maxZoom: 14,
                      });
                    }
                  }

                  onMapLoad(map);
                }}
                filteredLayers={selectedCategories}
                sx={{
                  width: 'calc(100vw - 660px)',
                  height: 'calc(100vh - var(--header-height) - var(--footer-height) - 80px)',
                }}
              />
            )}
        </Box>
      )}
    </Box>
  );
};
