import { Box, Card, Divider, Typography } from '@mui/material';
import React from 'react';
import BNGAssessment from './assets/bng_assessment_418_380.png';
import { FindOutMoreOverviewFragment } from './overview.fragment';

export const BNGAssessmentFragment = () => {
  return (
    <Box
      className="find-out-more-content"
      sx={{
        display: 'flex',
        direction: 'row',
        m: 'var(--content-spacing)',
        pl: 'var(--content-spacing)',
        pr: 'var(--content-spacing)',
      }}
    >
      <Box sx={{ m: '0 var(--content-spacing) 0 0' }}>
        <Typography variant="h4" sx={{ p: 0, fontWeight: 'bold', color: '#545454' }}>
          Biodiversity Net Gain (BNG) assessment
        </Typography>
        <Divider sx={{ m: 'var(--content-spacing-narrow) 0 var(--content-spacing-narrow) 0' }} />
        <Typography>
          <b>What is a BNG assessment?</b>
        </Typography>
        <Typography
          variant="body1"
          sx={{ m: 'var(--content-spacing-narrow) 0 var(--content-spacing-narrow) 0' }}
        >
          A Biodiversity Net Gain (BNG) assessment provides a comprehensive overview of the ecological value
          of a site based on the UKHab metric. Habitats are scored according to their type and
          distinctiveness. A BNG assessment can be used to determine a biodiversity baseline and track and
          monitor changes through time.
        </Typography>
        <Typography sx={{ mt: 2 }}>
          <b>What does a BNG assessment include?</b>
        </Typography>
        <Typography
          variant="body1"
          sx={{ m: 'var(--content-spacing-narrow) 0 var(--content-spacing-narrow) 0' }}
        >
          Results are provided in the form of digital maps and a report.
        </Typography>
        <Typography sx={{ mb: 2, fontWeight: 'bold' }}>
          Download a{' '}
          <a href="/habitat-mapping-and-bng-case-study.pdf" download>
            case study
          </a>
        </Typography>
        <Card
          sx={{
            p: 'var(--content-spacing-narrow)',
            maxWidth: '800px',
            mb: 'var(--content-spacing)',
          }}
        >
          <img src={BNGAssessment} style={{ width: '100%', maxWidth: '800px' }} />
        </Card>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 'bold',
            fontSize: '18px',
            color: '#545454',
            mt: 'var(--content-spacing)',
            mb: 'var(--content-spacing)',
          }}
        >
          Products Overview
        </Typography>
        <FindOutMoreOverviewFragment />
      </Box>
    </Box>
  );
};
