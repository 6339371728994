import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { Edit } from '@mui/icons-material';
import { Box, IconButton, styled } from '@mui/material';
import { Root, createRoot } from 'react-dom/client';

export class PointEditControl implements mapboxgl.IControl {
  private container: HTMLElement;
  private root: Root;
  private map: mapboxgl.Map;
  private draw: MapboxDraw;

  constructor(draw: MapboxDraw) {
    this.container = document.createElement('div');
    this.container.className = 'mapboxgl-ctrl';
    this.root = createRoot(this.container);
    this.draw = draw;

    this.render();
  }

  render() {
    this.root.render(<EditPointsComponent draw={this.draw} />);
  }

  onAdd(map) {
    this.map = map;
    return this.container;
  }

  onRemove() {
    this.container.parentNode.removeChild(this.container);
    this.map = undefined;
  }
}

const ControlContainer = styled(Box)({
  backgroundColor: 'white',
  borderRadius: '4px',
});

export const EditPointsComponent: React.FC<{ draw: MapboxDraw }> = ({ draw }) => {
  return (
    <ControlContainer id="map-edit-polygon-control">
      <IconButton
        title="Edit polygon"
        size="small"
        onClick={() => {
          const featureIds = draw.getSelectedIds();

          if (featureIds?.length) {
            draw.changeMode('direct_select' as any, { featureId: featureIds[0] });
          } else {
            draw.changeMode('draw_polygon');
            // draw.changeMode('custom_polygon');
          }
        }}
        sx={{
          '& .MuiSvgIcon-root': { fontSize: '20px', color: 'black' },
        }}
      >
        <Edit />
      </IconButton>
    </ControlContainer>
  );
};
