import {
  TextField,
  Button,
  MenuItem,
  Box,
  Typography,
  Divider,
  LinearProgress,
  InputAdornment,
  IconButton,
  Alert,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { IProduct } from '../../core/interfaces/product.interface';
import { ProductComponent } from '.';
import { Product } from '../../core/entities/product.entity';
import useGentian from '../../core/hookies/gentian.hook';
import { Clear } from '@mui/icons-material';

interface ProductRegistrationFormProps {
  productToEdit?: IProduct;
  onSubmit: (product: IProduct) => void;
}

export const ProductRegistrationFormComponent: React.FC<ProductRegistrationFormProps> = ({
  productToEdit,
  onSubmit,
}) => {
  const [product, setProduct] = useState<IProduct>(new Product());
  const productToEditRef = useRef(productToEdit);

  const {
    mutate: uploadFindOutMorePDF,
    data: uploadResult,
    isError,
    isSuccess,
    isLoading: uploadingFile,
    reset,
  } = useGentian().uploadFindOutMorePDF;

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setProduct((prevProduct) => ({
      ...prevProduct,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (uploadResult) {
      setProduct({
        ...product,
        detailsURI: uploadResult.publicUrl,
      });
    }
  }, [uploadResult]);

  useEffect(() => {
    productToEditRef.current = productToEdit;
    setProduct(productToEdit || new Product());
  }, [productToEdit]);

  const handleSubmit = () => {
    onSubmit(product);
  };

  return (
    <Box display="flex" flexDirection="column" gap={2} sx={{ p: 'var(--content-spacing-narrow)' }}>
      {productToEditRef.current && (
        <Typography
          sx={{ backgroundColor: 'var(--gentian-tertiary)', borderRadius: 1, pl: 2, py: '4px', mb: 1 }}
        >
          You are editing the product: <i>{[...productToEditRef.current.name]}</i>
        </Typography>
      )}
      <TextField
        name="name"
        label="Product Name"
        required
        value={product.name}
        onChange={handleInputChange}
      />
      <TextField
        name="shortDescription"
        label="Short Description"
        required
        value={product.shortDescription}
        onChange={handleInputChange}
        multiline
        rows={3}
      />
      <label htmlFor="product-image-input">
        <Typography sx={{ color: 'gray' }}>Find Out More File (PDF)*</Typography>
      </label>
      {uploadingFile ? (
        <>
          <Typography variant="body2">Uploading the PDF file...</Typography>
          <LinearProgress />
        </>
      ) : isSuccess || product?.detailsURI ? (
        <TextField
          id="product-details-input"
          name="detailsURI"
          label=""
          disabled
          value={product.detailsURI}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    setProduct((prevProduct) => ({
                      ...prevProduct,
                      detailsURI: '',
                    }));
                  }}
                >
                  <Clear />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      ) : isError ? (
        <Alert
          severity="error"
          onClose={() => {
            reset();
          }}
        >
          Error uploading file
        </Alert>
      ) : (
        <TextField
          id="product-details-input"
          name="details"
          type="file"
          required
          variant="outlined"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const file = (event.target as HTMLInputElement).files?.[0];
            if (file) {
              uploadFindOutMorePDF({ code: product.name, file });
            }
          }}
        />
      )}
      <label htmlFor="product-image-input">
        <Typography sx={{ color: 'gray' }}>Image (PNG)*</Typography>
      </label>
      <TextField
        id="product-image-input"
        name="image"
        type="file"
        required
        variant="outlined"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const file = (event.target as HTMLInputElement).files?.[0];
          if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
              setProduct((prevProduct) => ({
                ...prevProduct,
                image: reader.result as string,
              }));
            };
            reader.readAsDataURL(file);
          }
        }}
      />
      <TextField name="size" label="Size" select value={product.size} onChange={handleInputChange}>
        <MenuItem value="small">Small</MenuItem>
        <MenuItem value="large">Large</MenuItem>
      </TextField>
      <Divider />
      <Typography variant="h6">Product Card Preview</Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: product.size === 'small' ? 'center' : 'start',
          overflowX: 'auto',
          p: 2,
        }}
      >
        <Box
          sx={{
            width: 'fit-content',
          }}
        >
          <ProductComponent product={product} />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
        <Button
          variant="contained"
          sx={{ bgcolor: 'var(--gentian-tertiary)', width: '40%' }}
          onClick={() => {
            setProduct(new Product());
            productToEditRef.current = undefined;
          }}
        >
          {productToEditRef.current ? `Cancel` : `Clear`}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          sx={{ width: '40%' }}
          disabled={!product.name || !product.shortDescription || !product.image}
        >
          Save
        </Button>
      </Box>
    </Box>
  );
};
