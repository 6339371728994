import {
  Box,
  Typography,
  TextField,
  Tabs,
  Tab,
  Switch,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Slider,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { IResultLayer } from '../../core/interfaces/result.interface';
import { ChartPattern } from '../../core/enums/chart-pattern.enum';
import { MuiColorInput } from 'mui-color-input';

export interface ChartTabConfigurationProps {
  layer: IResultLayer;
  onLayerEdit: (index: number, layer: IResultLayer) => void;
}

export const ChartTabConfiguration = ({ layer, onLayerEdit }) => {
  const [layerData, setLayerData] = useState<IResultLayer>(layer);
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const [timeoutToken, setTimeoutToken] = useState<NodeJS.Timeout>();

  const [displayName, setDisplayName] = useState<string>(layerData?.tab?.displayName || '');

  useEffect(() => {
    if (timeoutToken) clearTimeout(timeoutToken);

    const token = setTimeout(() => {
      onLayerEdit?.(layerData);
      setTimeoutToken(undefined);
    }, 300);

    setTimeoutToken(token);
  }, [layerData]);

  return (
    <Box display="flex" flexDirection="column" sx={{ width: '100%' }}>
      <Typography variant="body2">Chart tab configuration</Typography>
      <TextField
        label="Tab Display Name"
        value={displayName}
        fullWidth
        margin="normal"
        onChange={(e) => {
          setDisplayName(e.target.value);
        }}
        onBlur={() => {
          layerData.tab.displayName = displayName;
          setLayerData({ ...layerData });
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <label htmlFor="chart-visibility">Chart Visibility</label>
        <Switch
          id="chart-visibility"
          checked={layerData.tab.visible}
          onChange={(e) => {
            layerData.tab.visible = e.target.checked;
            setLayerData({ ...layerData });
          }}
        />
      </Box>

      <Box sx={{ display: 'flex', width: '100%', height: '420px', mt: 2 }}>
        <Tabs
          value={selectedTab}
          orientation="vertical"
          onChange={(_, idx) => setSelectedTab(idx)}
          aria-label="layers tabs"
          variant="scrollable"
          sx={{ borderRight: 1, borderColor: 'divider' }}
        >
          {layerData?.tab?.pieChart.map((chartData, index) => (
            <Tab
              key={`${chartData.className}_${index}`}
              label={`${chartData.className}`}
              sx={{ fontSize: '10px' }}
            />
          ))}
        </Tabs>

        {layerData.tab.pieChart.map((c, i) => (
          <Box
            key={i}
            display={i === selectedTab ? 'flex' : 'none'}
            justifyContent="space-evenly"
            alignContent="flex-start"
            flexDirection="column"
            sx={{ ml: 2 }}
          >
            <TextField
              label={`Category ${i + 1} Display Name`}
              value={c.className}
              fullWidth
              onChange={(e) => {
                c.className = e.target.value;
                setLayerData({ ...layerData });
              }}
            />
            <TextField
              label="Percent"
              value={c.percent}
              fullWidth
              onChange={(e) => {
                c.percent = +e.target.value;
                setLayerData({ ...layerData });
              }}
            />
            <MuiColorInput
              label="Fill Color"
              value={c.fillColor}
              fullWidth
              isAlphaHidden={true}
              format="hex"
              onChange={(value) => {
                c.fillColor = value;
                setLayerData({ ...layerData });
              }}
            />
            <Box sx={{ mt: 2, mb: 1 }}>
              <Typography gutterBottom sx={{ fontSize: '12px' }}>
                Fill Transparency ({c.fillTransparency})
              </Typography>
              <Slider
                value={c.fillTransparency}
                onChange={(e, value) => {
                  c.fillTransparency = +value;
                  setLayerData({ ...layerData });
                }}
                step={0.1}
                min={0}
                max={1}
                valueLabelDisplay="auto"
              />
            </Box>

            <FormControl fullWidth>
              <InputLabel id={`fill-pattern-select-label-${i}`}>Fill Pattern</InputLabel>
              <Select
                labelId={`fill-pattern-select-label-${i}`}
                id={`fill-pattern-select-${i}`}
                value={c.fillPattern}
                label="Fill Pattern"
                onChange={(e) => {
                  c.fillPattern = e.target.value as ChartPattern;
                  setLayerData({ ...layerData });
                }}
              >
                {Object.keys(ChartPattern).map((key) => (
                  <MenuItem key={key} value={ChartPattern[key]}>
                    {ChartPattern[key]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
