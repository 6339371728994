import './style.scss';
import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { DataGrid, GridColDef, GridValueFormatterParams } from '@mui/x-data-grid';
import { OpenInNew } from '@mui/icons-material';
import { ISharedResults } from '../../core/interfaces/shared-results.interface';

export interface SharedResultsProps {
  sharedResults: ISharedResults[];
}

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'Id',
    headerAlign: 'center',
  },
  {
    field: 'projectId',
    headerName: 'Project Id',
    headerAlign: 'center',
  },
  {
    field: 'projectName',
    headerName: 'Project Name',
    minWidth: 128,
    headerAlign: 'center',
    flex: 1,
  },
  {
    field: 'totalAreaUnit',
    headerName: 'Total Area',
    minWidth: 128,
    headerAlign: 'center',
    align: 'center',
    flex: 1,
  },
  {
    field: 'lastAccess',
    headerName: 'Last Access',
    minWidth: 128,
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => new Date(params.value).toLocaleString(),
  },
  {
    field: 'owner',
    headerName: 'Shared By',
    minWidth: 128,
    headerAlign: 'center',
    align: 'center',
    flex: 1,
  },
  {
    field: 'sharedAt',
    headerName: 'Shared At',
    minWidth: 128,
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => new Date(params.value).toLocaleString(),
  },
  {
    field: 'requestDate',
    headerName: 'Request Date',
    maxWidth: 160,
    minWidth: 96,
    align: 'center',
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => new Date(params.value).toLocaleDateString(),
    headerAlign: 'center',
  },
  {
    field: 'result',
    headerName: 'Open Result',
    width: 180,
    renderCell: (params) => (
      <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
        <Button variant="contained" onClick={() => (window.location.href = `/survey-result/${params.value}`)}>
          Open Result <OpenInNew />
        </Button>
      </Box>
    ),
    headerAlign: 'center',
  },
];

export const SharedResultsComponent: React.FC<SharedResultsProps> = ({ sharedResults }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [searchText, setSearchText] = useState('');

  const handleSearchChange = (event) => {
    setSearchText(event.target.value.toString());
  };

  const filteredRows = sharedResults
    .map((r) => ({
      ...r,
      id: r._id,
      result: r.projectId,
      owner: `${r.customerName} <${r.customerEmail}> - ${r.company}`,
      totalAreaUnit: `${r.totalArea} ${r.unit}`,
    }))
    .filter(
      (row) =>
        row.projectName.includes(searchText) ||
        row.customerName.includes(searchText) ||
        row.customerEmail.includes(searchText) ||
        row.company.includes(searchText) ||
        new Date(row.requestDate).toLocaleDateString().includes(searchText),
    );

  return !isMobile ? (
    <Box>
      <TextField
        value={searchText}
        onChange={handleSearchChange}
        placeholder="Search by name or date"
        variant="standard"
        sx={{ width: '256px', mt: 2 }}
      />

      <DataGrid
        className="pending-projects-table"
        rows={filteredRows}
        columns={columns}
        disableColumnFilter
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
          sorting: {
            sortModel: [
              {
                field: 'lastAccess',
                sort: 'desc',
              },
            ],
          },
          columns: {
            columnVisibilityModel: {
              id: false,
              projectId: false,
            },
          },
        }}
        pageSizeOptions={[5, 10, 20, 50, 100]}
        disableRowSelectionOnClick
      />
    </Box>
  ) : (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {sharedResults.map((sharedResult) => (
        <Box key={sharedResult._id} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Card
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              mt: 'var(--content-spacing-narrow)',
            }}
          >
            <CardHeader title={sharedResult.projectName} />
            <CardContent>
              <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                {`Project Name: ${sharedResult.projectName}`}
                <Divider />
                {`Last Access: ${new Date(sharedResult.lastAccess).toLocaleDateString()}`}
                <br />
                {`Request date: ${new Date(sharedResult.requestDate).toLocaleDateString()}`}
                <br />
                {`Shared at: ${new Date(sharedResult.sharedAt).toLocaleDateString()}`}
                <Divider />
                {`Shared by: ${sharedResult.customerName} <${sharedResult.customerEmail}> - ${sharedResult.company}`}
                <br />
                {`Total Area: ${sharedResult.totalArea} ${sharedResult.unit}`}
              </Box>
            </CardContent>
            <CardActions>
              <Box>
                <Button
                  variant="contained"
                  onClick={() => (window.location.href = `/survey-result/${sharedResult._id}`)}
                >
                  Result <OpenInNew />
                </Button>
              </Box>
            </CardActions>
          </Card>
        </Box>
      ))}
    </Box>
  );
};
