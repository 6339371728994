import { Box, CircularProgress, Drawer, Typography, useMediaQuery, useTheme } from '@mui/material';
import LayersComponent from '../../Components/Layers';
import { MapComponent } from '../../Components/Map';
import { Res } from '../../resources';
import React, { useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import * as turf from '@turf/turf';
import { useParams } from 'react-router';
import useGentian from '../../core/hookies/gentian.hook';
import {
  ResultSource,
  buildSources,
  buildInspectorTool,
  buildLayersFill,
  buildLayersPatterns,
  buildLayersOutline,
  buildLayersImagery,
} from '../../core/builders/layer.builder';
import { datadogLogs } from '@datadog/browser-logs';
import { MAP_STYLES } from '../../Components/Map/MapStyleSwitchControl';
import { useAuth0 } from '@auth0/auth0-react';

export const popup = new mapboxgl.Popup({
  closeButton: true,
  closeOnClick: false,
});

export const SurveyResultPage = () => {
  const [selectedCategories, setSelectedCategories] = useState<{ id: string; visible: boolean }[]>([]);
  const { projectId } = useParams();
  const { user } = useAuth0();

  const { getResultByProjectId } = useGentian();
  const { mutate: saveSharedResultAccess, isIdle } = useGentian().addResult;
  const { data: result, isLoading } = getResultByProjectId(projectId);

  const [sources, setSources] = useState<ResultSource[]>([]);
  const [mapLayers, setMapLayers] = useState<any>([]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileOpen, setMobileOpen] = useState(false);

  const inspector = React.useRef(new Map<string, string>());

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    if (isIdle && user?.sub && result?.createdBy && user?.sub !== result?.createdBy) {
      saveSharedResultAccess(projectId);
    }
  }, [user, result]);

  useEffect(() => {
    if (result?.layers?.length && sources.length) {
      inspector.current = new Map(buildInspectorTool(sources, result));
    }
  }, [sources]);

  const handleOnMouseEnter = (
    map: mapboxgl.Map,
    sourceId: string,
    e: mapboxgl.MapMouseEvent & mapboxgl.EventData,
  ) => {
    popup.setLngLat(e.lngLat).setHTML(inspector.current.get(sourceId)).addTo(map);
  };

  const handleCheckboxChange = (checked, data) => {
    const selectedCategory = selectedCategories.filter((c) => c.id.startsWith(data.id));
    if (selectedCategory?.length) {
      selectedCategory.forEach((c) => (c.visible = checked));
    } else {
      selectedCategories.push({ id: data.id, visible: checked });
    }
    setSelectedCategories([...selectedCategories]);
  };

  const handleOnSelect = (selected, layer) => {
    const prefix = `${layer.resultId}_${layer.details.columnName}_`;
    selectedCategories
      ?.filter((c) => c?.id?.startsWith(prefix))
      .forEach((c) => {
        const visible =
          layer.details.classes.find((cl) =>
            c.id.replace(prefix, '').startsWith(cl.category.toString().replace(/\s/g, '-')),
          )?.defaultEnabled !== false;
        c.visible = selected && visible;
      });
    setSelectedCategories([...selectedCategories]);
  };

  useEffect(() => {
    if (mapLayers?.length) {
      const allClasses = result?.layers
        .map((layer) =>
          layer.details.classes.map((c) => ({
            id: `${layer.resultId}_${layer.details.columnName}_${c.category.toString().replace(/\s/g, '-')}`,
            visible: layer.details.defaultEnabled !== false && c.defaultEnabled !== false, // Default to true if undefined
          })),
        )
        .flat();
      const selCategories = mapLayers?.map((mapLayer) => ({
        id: mapLayer.id,
        visible: allClasses.find((c) => (mapLayer.id as string).startsWith(c.id))?.visible !== false || false,
      }));
      setSelectedCategories([...selCategories]);
    }
  }, [mapLayers]);

  return isLoading ? (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
    </Box>
  ) : !result?.layers?.length ? (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <h1>No data found</h1>
    </Box>
  ) : (
    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
      {isMobile ? (
        <>
          <Box
            sx={{
              width: '10vw',
              height: 'calc(100vh - var(--header-height) - var(--footer-height))',
              backgroundColor: 'white',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={handleDrawerToggle}
          >
            <Typography variant="h6" sx={{ transform: 'rotate(-90deg)' }}>
              Layers
            </Typography>
          </Box>
          <Drawer
            anchor="left"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <LayersComponent
              result={result}
              onCheckboxChange={handleCheckboxChange}
              onSelect={handleOnSelect}
            />
          </Drawer>
        </>
      ) : (
        <Box
          sx={{
            width: '320px',
            height: 'alto',
            overflowY: 'auto',
            overflowX: 'hidden',
            minWidth: '320px',
            backgroundColor: 'white',
          }}
        >
          <LayersComponent
            result={result}
            onCheckboxChange={handleCheckboxChange}
            onSelect={handleOnSelect}
          />
        </Box>
      )}
      <MapComponent
        accessToken={Res.components.maps.MAPBOX_TOKEN || ''}
        readOnly
        hideMapStyleSwitchControl
        style={MAP_STYLES.SATELLITE}
        onLoad={(map) => {
          const sources = result?.layers?.map(buildSources).flat().reverse();
          const patterns = buildLayersPatterns(sources, result);
          const layers = [
            ...(buildLayersImagery(sources) || []),
            ...(buildLayersFill(sources, result) || []),
            ...(buildLayersOutline(sources, result) || []),
          ];

          setSources(sources);
          setMapLayers([...layers]);

          sources.forEach((source) => {
            if (map.getSource(source.id)) {
              map.removeSource(source.id);
            }
            map.addSource(source.id, source.data);

            map.on('mousedown', source.id, (e) => {
              handleOnMouseEnter(map, source.id, e as any);
            });

            map.on('mouseenter', source.id, () => {
              map.getCanvas().style.cursor = 'pointer';
            });

            map.on('mouseleave', source.id, () => {
              map.getCanvas().style.cursor = '';
            });
          });

          Object.entries(patterns).forEach(([id, pattern]) => {
            map.loadImage(pattern, (error, image) => {
              if (error) {
                datadogLogs.logger.error('Error loading image', {
                  error,
                  name: 'loadImage',
                  id,
                  pattern,
                });
                throw error;
              }
              if (map.hasImage(id)) {
                map.removeImage(id);
              }
              map.addImage(id, image);
            });
          });

          layers?.forEach((layer) => {
            if (map.getLayer(layer.id)) {
              map.removeLayer(layer.id);
            }
            map.addLayer(layer);
          });

          if (sources.length) {
            const boundSource = (sources.find((s) => !!(s.data as any)?.data)?.data as any)?.data;
            if (boundSource) {
              map.fitBounds(turf.bbox(boundSource) as any, {
                padding: 20,
                maxZoom: 14,
              });
            }
          }
        }}
        filteredLayers={selectedCategories}
        sx={{
          width: isMobile ? '90vw' : 'calc(100vw - 320px)',
          height: 'calc(100vh - var(--header-height) - var(--footer-height))',
        }}
      />
    </Box>
  );
};
