import { Box, Typography, Divider, Card } from '@mui/material';
import React from 'react';
import { FindOutMoreOverviewFragment } from './overview.fragment';
import GreenRoofRetrofit from './assets/green-roof-retrofit.png';

export const GreenRoofRetrofitFragment = () => {
  return (
    <Box
      className="find-out-more-content"
      sx={{
        display: 'flex',
        direction: 'row',
        m: 'var(--content-spacing)',
        pl: 'var(--content-spacing)',
        pr: 'var(--content-spacing)',
      }}
    >
      <Box sx={{ m: '0 var(--content-spacing) 0 0' }}>
        <Typography variant="h4" sx={{ p: 0, fontWeight: 'bold', color: '#545454' }}>
          Green roof retrofit
        </Typography>
        <Divider sx={{ m: 'var(--content-spacing-narrow) 0 var(--content-spacing-narrow) 0' }} />
        <Typography
          variant="body1"
          sx={{ m: 'var(--content-spacing-narrow) 0 var(--content-spacing-narrow) 0' }}
        >
          <i>
            Green roofs provide important refuge for flora and fauna and can significantly increase climate
            resilience, stormwater retention and air quality across cities.
          </i>
        </Typography>
        <Typography sx={{ mt: 2 }}>
          <b>What is Green Roof Retrofit?</b>
        </Typography>
        <Typography
          variant="body1"
          sx={{ m: 'var(--content-spacing-narrow) 0 var(--content-spacing-narrow) 0' }}
        >
          Our Green Roof Retrofit solution detects rooftops that have a load-bearing capacity of at least
          120kg/m2 (the weight of a saturated green roof) and can be immediately retrofitted with a green
          roof. Factors other than weight that may make the implementation of a green roof challenging, such
          as access to the roof, are not considered. Talk to us if you’d like to know more about the solar
          energy potential of these rooftops, or their potential contribution to biodiversity, climate
          resilience or stormwater retention. Please note: Before proceeding with a green roof retrofit, a
          structural survey is required to verify the exact load-bearing capacity of all roofs.
        </Typography>
        <Typography
          variant="body1"
          sx={{
            m: 'var(--content-spacing-narrow) 0 var(--content-spacing-narrow) 0',
            textAlign: 'justify',
          }}
        >
          <b>What does Green Roof Retrofit include</b>
          <br />
          Results are provided in the form of digital maps and a report.
        </Typography>
        <Typography sx={{ mb: 2, fontWeight: 'bold' }}>
          Download a{' '}
          <a href="/grid-and-grrf-case-study.pdf" download>
            case study
          </a>
        </Typography>
        <Card
          sx={{
            p: 'var(--content-spacing-narrow)',
            maxWidth: '800px',
            mb: 'var(--content-spacing)',
          }}
        >
          <img src={GreenRoofRetrofit} style={{ width: '100%' }} />
        </Card>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 'bold',
            fontSize: '18px',
            color: '#545454',
            mt: 'var(--content-spacing)',
            mb: 'var(--content-spacing)',
          }}
        >
          Products Overview
        </Typography>
        <FindOutMoreOverviewFragment />
      </Box>
    </Box>
  );
};
