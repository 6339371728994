import {
  Select,
  MenuItem,
  ListSubheader,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Divider,
} from '@mui/material';
import React from 'react';

const industryCategories = {
  Education: ['Academia and research activities', 'Educational services'],
  Finance: ['Insurance carriers', 'Financial investments', 'Financial services', 'Monetary authorities'],
  Governance: [
    'Government agency',
    'Local authority',
    'National security and international affairs',
    'Non-departmental public body (NDPB)',
    'Municipality',
  ],
  Land: [
    'Building construction',
    'Ecological consultant',
    'Land owner',
    'Land surveyer',
    'Property manager',
    'Urban planning',
  ],
  Manufacturing: [
    'Apparel manufacturing',
    'Chemical manufacturing',
    'Electrical equipment manufacturing',
    'Motor vehicle manufacturing',
    'Paper manufacturing',
    'Textile manufacturing',
  ],
  'Natural resources': [
    'Agriculture',
    'Fishing and hunting',
    'Forestry',
    'Mining',
    'Oil and gas extraction',
    'Renewable energy',
  ],
  'Non-profit': ['Charity sector', 'NGO', 'Private individual'],
  Service: [
    'Emergency services',
    'Food and beverage',
    'Health and health care',
    'Legal services',
    'Telecommunications',
    'Utilities',
  ],
  Technology: [
    'Computing infrastructure',
    'Professional, scientific or technical services',
    'Space research and technology',
  ],
  Transport: ['Air transportation', 'Rail transportation', 'Road transportation', 'Shipping'],
  Other: ['Other'],
};

export interface IndustrySelectProps {
  value: string;
  onChange: (event: SelectChangeEvent<string>) => void;
  sx?: any;
  required?: boolean;
}

export const IndustrySelectComponent: React.FC<IndustrySelectProps> = ({ value, onChange, sx, required }) => {
  const getItems = () => {
    const items = [];
    for (const [category, industries] of Object.entries(industryCategories)) {
      items.push(
        <ListSubheader key={`other_${category}`}>
          {category === 'Other' ? <Divider sx={{ p: '10px 0 0 0' }} /> : category}
        </ListSubheader>,
      );
      for (const industry of industries) {
        items.push(
          <MenuItem key={industry} value={industry}>
            {industry}
          </MenuItem>,
        );
      }
    }
    return items;
  };

  return (
    <FormControl fullWidth variant="standard" sx={sx} required={required || false}>
      <InputLabel>Industry</InputLabel>
      <Select value={value} onChange={onChange} label="Industry" name="industry" variant="standard">
        {getItems()}
      </Select>
    </FormControl>
  );
};
