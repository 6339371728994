import React, { useState } from 'react';
import { Box, Button, TextField, Modal, Divider, useMediaQuery, useTheme, Typography } from '@mui/material';

export interface ProjectUpdateModalProps {
  project: any;
  field: string;
  onClose: () => void;
  onSaveClick: (value: string) => void;
}

export const ProjectUpdateModal = ({ project, field, onClose, onSaveClick }: ProjectUpdateModalProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [value, setValue] = useState(project[field] ?? '');

  return (
    <Modal open onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: isMobile ? '90vw' : '600px',
          height: isMobile ? '90vh' : 'auto',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography variant="h6" component="h2">
          Update {field}
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            my: 2,
          }}
        >
          <TextField
            fullWidth
            variant="outlined"
            value={value}
            sx={{ mr: 2 }}
            onChange={(e) => setValue(e.target.value)}
          />
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={() => onSaveClick(value)}>Save</Button>
          <Button onClick={onClose}>Close</Button>
        </Box>
      </Box>
    </Modal>
  );
};
