import { Box, Typography, Divider, Card } from '@mui/material';
import React from 'react';
import { FindOutMoreOverviewFragment } from './overview.fragment';
import HabitatAssessment from './assets/habitat-assessment.png';

export const UrbanHabitatAssessmentFragment = () => {
  return (
    <Box
      className="find-out-more-content"
      sx={{
        display: 'flex',
        direction: 'row',
        m: 'var(--content-spacing)',
        pl: 'var(--content-spacing)',
        pr: 'var(--content-spacing)',
      }}
    >
      <Box sx={{ m: '0 var(--content-spacing) 0 0' }}>
        <Typography variant="h4" sx={{ p: 0, fontWeight: 'bold', color: '#545454' }}>
          Urban greening assessment
        </Typography>
        <Divider sx={{ m: 'var(--content-spacing-narrow) 0 var(--content-spacing-narrow) 0' }} />
        <Typography
          variant="body1"
          sx={{ m: 'var(--content-spacing-narrow) 0 var(--content-spacing-narrow) 0' }}
        >
          <i>
            Greenery in cities fosters biodiversity, enhances mental health, and creates vibrant, livable
            spaces for communities to thrive
          </i>
        </Typography>
        <Typography sx={{ mt: 2 }}>
          <b>What is an urban greening assessment?</b>
        </Typography>
        <Typography
          variant="body1"
          sx={{ m: 'var(--content-spacing-narrow) 0 var(--content-spacing-narrow) 0' }}
        >
          An urban greening assessment maps the amount of greenery, including trees, grass and other vegetated
          areas, in your area. It also identifies and maps non-vegetated spaces, such as paved areas,
          buildings and roads. For a given area, it quantifies the percentage of vegetation cover. This
          product can also be applied to a specific type of greenspace (e.g. residential gardens) to quantify
          the proportion of vegetation in each garden.
        </Typography>
        <Typography sx={{ mt: 2 }}>
          <b>What does an urban greening assessment include? </b>
        </Typography>
        <Typography
          variant="body1"
          sx={{ m: 'var(--content-spacing-narrow) 0 var(--content-spacing-narrow) 0' }}
        >
          Results are provided in the form of digital maps and a report.
        </Typography>
        <Card
          sx={{
            p: 'var(--content-spacing-narrow)',
            maxWidth: '800px',
            mb: 'var(--content-spacing)',
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: 'bold',
              fontSize: '14px',
              color: '#545454',
            }}
          >
            Example urban greening assessment (applied to residential gardens)
          </Typography>
          <img src={HabitatAssessment} style={{ width: '100%' }} />
        </Card>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 'bold',
            fontSize: '18px',
            color: '#545454',
            mt: 'var(--content-spacing)',
            mb: 'var(--content-spacing)',
          }}
        >
          Products Overview
        </Typography>
        <FindOutMoreOverviewFragment />
      </Box>
    </Box>
  );
};
