import './style.scss';

import React from 'react';
import Box from '@mui/material/Box';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import ChartTabs from './chart-tabs';
import LayerDetails from './details';
import { IResult } from '../../core/interfaces/result.interface';
import { IconButton, Switch, Tooltip } from '@mui/material';
import { Download, Share } from '@mui/icons-material';
import ResultSharingComponent from '../ResultSharing';

interface LayersComponentProps {
  result: IResult;
  onCheckboxChange?: (checked: boolean, classItem: any) => void;
  onSelect?: (selected: boolean, layer: any) => void;
}

const LayersComponent: React.FC<LayersComponentProps> = ({ result, onCheckboxChange, onSelect }) => {
  const { layers } = result || ({ layers: [] } as IResult);
  const [shareLinkModalOpen, setShareLinkModalModalOpen] = React.useState(false);

  return (
    <Box
      sx={{
        maxWidth: '320px',
        minWidth: '320px',
        display: 'flex',
        flexDirection: 'column',
        bgcolor: '#fff',
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Typography variant="body1" sx={{ p: '4px 16px 0 16px' }}>
          <b>{result?.project?.name}</b>
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <IconButton
            sx={{ mx: '16px', display: result?.status === 'published' ? 'block' : 'none' }}
            size="small"
            onClick={() => setShareLinkModalModalOpen(true)}
          >
            <Tooltip title="Share">
              <Share />
            </Tooltip>
          </IconButton>
          {!!result?.deliverableUrl && (
            <IconButton
              size="small"
              sx={{ mx: '16px' }}
              onClick={() => window.open(result.deliverableUrl, '_blank')}
            >
              <Tooltip title="Download">
                <Download />
              </Tooltip>
            </IconButton>
          )}
        </Box>
        {shareLinkModalOpen && (
          <ResultSharingComponent
            link={`${window.location.origin}/survey-result/${result?.project?._id}`}
            onClose={() => setShareLinkModalModalOpen(false)}
            projectId={result?.project?._id}
            hasDownloadLink={!!result?.deliverableUrl}
          />
        )}
      </Box>
      <Typography sx={{ p: '0px 16px 0px 16px', fontSize: '10px' }}>
        Image from year {result?.yearOfReference}
      </Typography>
      <ChartTabs chartData={layers?.filter((layer) => layer.tab?.visible).map((layer) => layer.tab)} />
      {layers.map(
        (layer, index) =>
          layer.details.visible && (
            <Accordion key={index} sx={{}}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
                sx={{ '& .MuiAccordionSummary-content': { margin: '0px' } }}
              >
                <Switch
                  checked={layer.details.defaultEnabled !== false} // also check for undefined for retrocompatibility
                  onClick={(e) => {
                    e.stopPropagation();
                    const checked = (e.target as any).checked;
                    onSelect?.(checked, layer);
                    layer.details.defaultEnabled = checked;
                  }}
                ></Switch>
                <Typography sx={{ lineHeight: '38px' }}>{layer?.displayName}</Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{ m: 0, pl: 1 }}
                hidden={layer.details.defaultEnabled === false || !layer?.details?.classes?.length} // also check for undefined for retrocompatibility
              >
                <LayerDetails layer={layer} onCheckboxChange={onCheckboxChange} />
              </AccordionDetails>
            </Accordion>
          ),
      )}
    </Box>
  );
};

export default LayersComponent;
