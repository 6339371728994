import { Alert, Box, Divider, Snackbar, Typography, styled, useMediaQuery, useTheme } from '@mui/material';
import { IProduct } from '../../core/interfaces/product.interface';
import { forwardRef, useState } from 'react';
import React from 'react';
import { CheckoutHeaderComponent } from './CheckoutHeader';
import { CheckoutFooterComponent } from './CheckoutFooter';
import { ProductListComponent } from './ProductList';
import { ProjectFormComponent } from './ProjectForm';
import { ConfirmationDialog } from '../ConfirmationDialog';
import CryptoJS from 'crypto-js';
import { IProject } from '../../core/interfaces/project.interface';
import { IAOIInfo } from '../../core/interfaces/aoi-info.interface';
import useGentian from '../../core/hookies/gentian.hook';
import { useQueryClient } from 'react-query';

const CheckoutContainer = styled(Box)({
  backgroundColor: 'white',
  padding: 'var(--content-spacing-narrow)',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  height: '80vh',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: '0px 0px 8px 0px rgba(0,0,0,0.75)',
});

const ContentContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  flexWrap: 'wrap',
  overflowY: 'auto',
  overflowX: 'hidden',
});

export interface CheckoutProps {
  products?: IProduct[];
  AOIInfo?: IAOIInfo;
  ref?: React.Ref<any>;
  sx?: React.CSSProperties;
  onClose?: () => void;
  onSuccess?(project: IProject): void;
}

export const CheckoutComponent: React.ForwardRefExoticComponent<CheckoutProps> = forwardRef(
  ({ products, AOIInfo, onClose, onSuccess, sx }, ref) => {
    const isMobile = useMediaQuery('(max-width: 1040px)');

    const queryClient = useQueryClient();

    const [selectedProducts, setSelectedProducts] = useState<IProduct[]>([]);
    const [projectName, setProjectName] = useState<string>('');
    const [projectDescription, setProjectDescription] = useState<string>('');
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [step, setStep] = useState(0);

    const { mutate: createProject } = useGentian().createProject;
    const { getAccount } = useGentian();
    const { data: accountData } = getAccount();
    const account = accountData?.data;

    const getTooltipText = () => {
      return selectedProducts?.length
        ? !projectName
          ? 'Please enter a project name to continue'
          : `Request a quote for ${selectedProducts.length} products`
        : 'Select one or more products to continue';
    };

    const handleSubmitProject = () => {
      const project = {
        name: projectName,
        description: projectDescription,
        AOI: AOIInfo?.shape,
        products: selectedProducts.map((p) => ({ name: p.name, id: p._id })),
        totalArea: AOIInfo?.TotalArea,
        unit: AOIInfo?.units,
        AOIThumbnail: AOIInfo?.thumbnail,
        customerName: account?.name || account.user_metadata?.name || account?.email || 'Anonymous',
        email: account?.email || 'Anonymous',
        company: account?.user_metadata?.company || 'Anonymous',
        jobTitle: account?.user_metadata?.jobTitle || 'Anonymous',
        country: account?.user_metadata?.country || 'Anonymous',
      };

      const jsonString = JSON.stringify(project);
      const projectHash = CryptoJS.SHA256(jsonString).toString(CryptoJS.enc.Hex);

      createProject(
        { ...project, projectHash },
        {
          onSuccess: () => {
            queryClient.invalidateQueries('projects');
            setShowConfirmation(false);
            onSuccess(project);
          },
          onError: (error) => {
            setSnackbarMessage(`Failed to create project. Please try again later. ${error}`);
            setSnackbarOpen(true);
          },
        },
      );
    };

    const handleCloseSnackbar = () => {
      setSnackbarOpen(false);
    };

    return (
      <CheckoutContainer ref={ref} sx={{ ...sx, height: isMobile ? '90vh' : '80vh' }}>
        <CheckoutHeaderComponent onClose={onClose} />
        <Divider />
        <ContentContainer>
          <Box
            sx={{
              minWidth: isMobile ? '100%' : '550px',
              width: isMobile ? '100%' : '45%',
              textAlign: 'center',
              overflowY: 'auto',
              height: '65vh',
              display: !isMobile ? 'block' : step === 0 ? 'block' : 'none',
            }}
          >
            <ProductListComponent
              products={products}
              onProductSelected={(product) => {
                setSelectedProducts([...selectedProducts, product]);
              }}
              onProductDeselected={(product) => {
                setSelectedProducts(selectedProducts.filter((p) => p._id !== product._id));
              }}
            />
          </Box>

          <Box
            sx={{
              width: isMobile ? '100%' : '40%',
              minWidth: isMobile ? '100%' : '380px',
              textAlign: 'center',
              overflowY: 'auto',
              height: '65vh',
              display: !isMobile ? 'block' : step === 1 ? 'block' : 'none',
            }}
          >
            <ProjectFormComponent
              AOIInfo={AOIInfo}
              showError={!!selectedProducts?.length}
              onProjectChange={(name, description) => {
                setProjectName(name);
                setProjectDescription(description);
              }}
            />
          </Box>
        </ContentContainer>
        {<Divider />}
        <ConfirmationDialog
          title="Confirmation"
          open={showConfirmation}
          onClose={() => setShowConfirmation(false)}
          onAgree={() => handleSubmitProject()}
        >
          <Typography>
            You are about to submit a request for a quote for {selectedProducts?.length} products. Please
            confirm your request.
          </Typography>
        </ConfirmationDialog>
        <CheckoutFooterComponent
          disabled={!selectedProducts?.length || !projectName}
          text={getTooltipText()}
          onClose={onClose}
          onContinue={() => setShowConfirmation(true)}
          onNext={isMobile ? (step) => setStep(step) : undefined}
          onBack={isMobile ? (step) => setStep(step) : undefined}
          maxSteps={isMobile ? 1 : 0}
          step={isMobile ? step : 0}
        />
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </CheckoutContainer>
    );
  },
);
