import React from 'react';
import { useParams } from 'react-router-dom';
import { BNGAssessmentFragment } from './bng-assessment.fragment';
import { GreenRoofIdentificationFragment } from './green-roof-identification.fragment';
import { GreenRoofRetrofitFragment } from './green-roof-retrofit.fragment';
import { TreeSurveysFragment } from './tree-surveys.fragment';
import { UrbanHabitatAssessmentFragment } from './urban-habitat-assessment.fragment';
import { HabitatMappingFragment } from './habitat-mapping.fragment';

export const FindOutMorePage = () => {
  const { productName } = useParams();
  switch (productName) {
    case 'bng-assessment':
      return <BNGAssessmentFragment />;
    case 'green-roof-identification':
      return <GreenRoofIdentificationFragment />;
    case 'green-roof-retrofit':
      return <GreenRoofRetrofitFragment />;
    case 'habitat-mapping':
      return <HabitatMappingFragment />;
    case 'tree-surveys':
      return <TreeSurveysFragment />;
    case 'urban-habitat-assessment':
      return <UrbanHabitatAssessmentFragment />;
  }
};
