import React from 'react';
import { Typography, Container } from '@mui/material';
export const VerifyEmailPage: React.FC = () => {
  return (
    <Container
      maxWidth="sm"
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'calc(100vh - var(--header-height) - var(--footer-height))',
      }}
    >
      <div style={{ textAlign: 'center' }}>
        <Typography variant="h4" gutterBottom>
          Please Verify Your Email
        </Typography>
        <Typography variant="body1">
          In order to continue using our platform, please verify your email address by clicking on the
          verification link sent to your email.
        </Typography>
      </div>
    </Container>
  );
};
