import { Download } from '@mui/icons-material';
import { Button, useMediaQuery, useTheme } from '@mui/material';

export interface DownloadAOIProps {
  AOI: GeoJSON.FeatureCollection | undefined;
  variant?: 'IconOnly' | 'IconAndText';
  disabled?: boolean;
}

export const DownloadAOIComponent: React.FC<DownloadAOIProps> = ({ AOI, disabled, variant }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (!variant) variant = 'IconAndText';
  const downloadAOI = () => {
    if (AOI) {
      const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(AOI));
      const downloadAnchorNode = document.createElement('a');
      downloadAnchorNode.setAttribute('href', dataStr);
      downloadAnchorNode.setAttribute('download', `AOI_${new Date().toISOString()}.geojson`);
      document.body.appendChild(downloadAnchorNode);
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    }
  };

  return (
    <Button variant="contained" className="hide-on-fullscreen" onClick={downloadAOI} disabled={disabled}>
      {!isMobile && variant === 'IconAndText' && 'Download Boundary'}
      <Download />
    </Button>
  );
};
