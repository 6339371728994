import { Close } from '@mui/icons-material';
import { Box, IconButton, styled } from '@mui/material';

const HeaderContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

export interface CheckoutHeaderProps {
  onClose?: () => void;
}

export const CheckoutHeaderComponent: React.FC<CheckoutHeaderProps> = ({ onClose }) => (
  <HeaderContainer>
    <h3 style={{ marginTop: 0 }}>Select all the products you are interested in to receive a quote.</h3>
    <IconButton style={{ paddingTop: 0 }} onClick={() => onClose?.()}>
      <Close />
    </IconButton>
  </HeaderContainer>
);
