import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import MuiAppBar from '@mui/material/AppBar';
import {
  Box,
  Button,
  Link,
  Typography,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useAuth0 } from '@auth0/auth0-react';
import useGentian from '../../core/hookies/gentian.hook';
import { jwtDecode } from 'jwt-decode';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Res } from '../../resources';

interface HeaderProps {
  Logo: JSX.Element;
  Avatar: JSX.Element;
}

const Header = styled(MuiAppBar)(() => ({
  height: 'var(--header-height)',
  minHeight: 'var(--header-height)',
  maxHeight: 'var(--header-height)',
  backgroundColor: 'var(--gentian-primary)',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0 var(--content-spacing) 0 var(--content-spacing)',
  color: 'white',
  position: 'relative',
  width: '100%',
  top: 0,
  right: 0,
}));

export const HeaderComponent: React.FC<HeaderProps> = ({ Logo, Avatar }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileOpen, setMobileOpen] = useState(false);

  const { loginWithRedirect, isAuthenticated, isLoading, getAccessTokenSilently, user, logout } = useAuth0();
  const [permissions, setPermissions] = useState<string[]>([]);

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [loginWithRedirect]);

  useEffect(() => {
    const getUserRoles = async () => {
      const token = await getAccessTokenSilently();
      const decodedToken = jwtDecode(token);
      const permissions = (decodedToken as any).permissions;
      setPermissions(permissions);
    };

    getUserRoles();
  }, [getAccessTokenSilently]);

  const { mutate: updateAccount } = useGentian().updateAccount;
  useEffect(() => {
    const update = async () => {
      updateAccount(
        {
          ...user,
          user_metadata: {
            name: user.name || 'Unknown User',
            jobTitle: '',
            country: '',
            company: '',
            industry: '',
            phoneNumber: '',
            companySize: '',
            termsAgreed: false,
          },
        },
        {
          onSuccess: async () => {
            alert('Please verify your email address before continuing. Remember to check your spam folder.');
            await logout({ logoutParams: { returnTo: window.location.origin } });
          },
          onError: (error) => {
            console.error(error);
          },
        },
      );
    };

    if (isAuthenticated && user && !user.email_verified && localStorage.getItem('gop-auth-token')) {
      update();
    }
  }, [user, isAuthenticated, localStorage.getItem('gop-auth-token')]);

  const getLogo = () => (
    <Link underline="none" color="white" href="/" style={{ height: '70%' }}>
      {Logo}
    </Link>
  );

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        Menu
      </Typography>
      <List>
        {Res.feature_flags.ENABLE_DASHBOARD && (
          <ListItem component="a" href="/dashboard">
            <ListItemText primary="My Requests" />
          </ListItem>
        )}
        {Res.feature_flags.ENABLE_RESULT_UPLOAD && permissions.includes('create:results') && (
          <ListItem component="a" href="/result-upload">
            <ListItemText primary="Configure Survey Result" />
          </ListItem>
        )}
        {Res.feature_flags.ENABLE_PRODUCT_MANAGEMENT && permissions.includes('create:products') && (
          <ListItem component="a" href="/products">
            <ListItemText primary="Manage products" />
          </ListItem>
        )}
      </List>
    </Box>
  );

  return (
    <Header>
      {isMobile ? (
        <>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'block' } }}
          >
            <MenuIcon />
          </IconButton>
          <Drawer
            anchor="left"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </>
      ) : (
        <>{getLogo()}</>
      )}
      {isMobile ? (
        window.location.pathname !== '/survey-request' && (
          <Fab
            color="primary"
            aria-label="add"
            sx={{ position: 'fixed', bottom: 16, right: 16 }}
            onClick={() => (window.location.href = '/survey-request')}
          >
            <AddIcon />
          </Fab>
        )
      ) : (
        <>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              minWidth: '320px',
              justifyContent: 'start',
            }}
          >
            {Res.feature_flags.ENABLE_DASHBOARD && (
              <Link underline="none" color="white" href="/dashboard">
                <Typography style={{ paddingRight: '32px' }}>My Requests</Typography>
              </Link>
            )}
            {Res.feature_flags.ENABLE_RESULT_UPLOAD && permissions.includes('create:results') && (
              <Link underline="none" color="white" href="/result-upload">
                <Typography style={{ paddingRight: '32px' }}>Configure Survey Result</Typography>
              </Link>
            )}
            {Res.feature_flags.ENABLE_PRODUCT_MANAGEMENT && permissions.includes('create:results') && (
              <Link underline="none" color="white" href="/products">
                <Typography style={{ paddingRight: '32px' }}>Manage Products</Typography>
              </Link>
            )}
          </Box>
          <div />
          <div />
          <Button variant="contained">
            <Link underline="none" color="white" href="/survey-request">
              Request a Survey
            </Link>
          </Button>
        </>
      )}
      {Avatar}
    </Header>
  );
};
