import { Close } from '@mui/icons-material';
import { Box, IconButton, Typography, styled, useMediaQuery, useTheme } from '@mui/material';
import logo from './../../Assets/logo.png';

export interface CheckoutSuccessProps {
  children?: React.ReactNode;
  onClose?: () => void;
}

const SuccessContainer = styled(Box)({
  backgroundColor: 'white',
  padding: 'var(--content-spacing-narrow)',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80vw',
  border: '1px solid #000',
  borderRadius: '8px',
  boxShadow: '0px 0px 8px 0px rgba(0,0,0,0.75)',
});

const ContentContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  flexWrap: 'wrap',
  padding: 'var(--content-spacing)',
});

const HeaderContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

export const CheckoutSuccessComponent: React.FC<CheckoutSuccessProps> = ({ children, onClose }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <SuccessContainer
      sx={{ width: isMobile ? '80vw' : '50vw', height: isMobile ? '90vh' : undefined, overflowY: 'auto' }}
    >
      <HeaderContainer>
        <div></div>
        <IconButton style={{ paddingTop: 0 }} onClick={() => onClose?.()}>
          <Close />
        </IconButton>
      </HeaderContainer>
      <ContentContainer>
        <img src={logo} alt="logo" style={{ maxHeight: isMobile ? '64px' : '128px' }} />
        <Typography variant="h6" textAlign="center" sx={{ paddingTop: 'var(--content-spacing)' }}>
          Thank you for submitting a quote request. A member of our team will be in touch shortly.
        </Typography>
        <Typography sx={{ fontSize: '12px', padding: 'var(--content-spacing)' }} textAlign="center">
          You can contact us at <a href="mailto:info@gentian.team"> info@gentian.team</a> for any questions.
        </Typography>
        {children}
      </ContentContainer>
    </SuccessContainer>
  );
};
