import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Divider, Typography } from '@mui/material';
import { IProduct } from '../../core/interfaces/product.interface';
import { ProductRegistrationFormComponent } from '../../Components/Product/ProductRegistrationForm';
import { ProductListComponent } from '../../Components/Checkout/ProductList';
import { ConfirmationDialog } from '../../Components/ConfirmationDialog';
import useGentian from '../../core/hookies/gentian.hook';
import { Product } from '../../core/entities/product.entity';

export const ProductsPage: React.FC = () => {
  const [products, setProducts] = useState<IProduct[]>([]);
  const [product, setProduct] = useState<IProduct | null>();
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);
  const [productToDelete, setProductToDelete] = React.useState<IProduct | null>(null);

  const { getAllProducts } = useGentian();
  const { data: allProducts, isLoading, isFetched, refetch: reloadProducts, isFetching } = getAllProducts();
  const {
    mutate: createProduct,
    isSuccess: successCreate,
    isLoading: isCreating,
  } = useGentian().createProduct;
  const {
    mutate: updateProduct,
    isSuccess: successUpdate,
    isLoading: isUpdating,
  } = useGentian().updateProduct;

  useEffect(() => {
    if (allProducts && isFetched) {
      setProducts(allProducts);
    }
  }, [allProducts, isFetched]);

  const handleConfirm = () => {
    if (productToDelete) products.splice(products.indexOf(productToDelete), 1);
    setProducts([...products]);
    setConfirmDialogOpen(false);
    setProductToDelete(null);
  };

  const handleSubmit = (newProduct: IProduct) => {
    const productToChange = products.find((p) => p._id === newProduct._id || p === newProduct);

    if (!productToChange) {
      createProduct(newProduct);
    } else {
      updateProduct(newProduct);
    }
  };

  useEffect(() => {
    if (successCreate || successUpdate) {
      setProduct(undefined);
      reloadProducts();
    }
  }, [successCreate, successUpdate]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
      {isCreating || isUpdating || isLoading || isFetching ? (
        <Box
          sx={{
            width: '100%',
            height: 'calc(100vh - var(--header-height) - var(--footer-height) - 64px)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <CircularProgress />
          {isCreating || isUpdating ? (
            <Typography variant="h6">Saving product...</Typography>
          ) : (
            <Typography variant="h6">Loading updated product list...</Typography>
          )}
        </Box>
      ) : (
        <>
          <Box
            sx={{
              minWidth: '256px',
              width: '30vw',
              overflowX: 'hidden',
              overflowY: 'auto',
              height: 'calc(100vh - var(--header-height) - var(--footer-height))',
            }}
          >
            <Box sx={{ p: 'var(--content-spacing-narrow)' }}>
              <Typography variant="h5">Product Registration</Typography>
              <Divider sx={{ mt: 1 }} />
            </Box>
            <ProductRegistrationFormComponent productToEdit={product} onSubmit={handleSubmit} />
          </Box>
          <Box
            sx={{
              backgroundColor: 'white',
              width: '70vw',
              height: 'calc(100vh - var(--header-height) - var(--footer-height))',
            }}
          >
            <Box sx={{ p: 'var(--content-spacing-narrow)' }}>
              <Typography variant="h6">Product List</Typography>
              <Divider sx={{ mt: 1 }} />
            </Box>
            <Box
              sx={{
                overflowX: 'hidden',
                overflowY: 'auto',
                height: 'calc(100vh - var(--header-height) - var(--footer-height) - 64px)',
              }}
            >
              <ProductListComponent
                products={products}
                displayAdminControls
                onProductEnabledChange={(product) => {
                  const productToChange = products?.find((p) => p === product);
                  if (productToChange) productToChange.enabled = product.enabled;
                  updateProduct(productToChange);
                }}
                onSelectedForDeletion={(product) => {
                  const productFound = products?.find((p) => p === product);
                  if (productFound) {
                    setProductToDelete(productFound);
                    setConfirmDialogOpen(true);
                  }
                }}
                onSelectedForEdition={(product) => {
                  setProduct(product);
                }}
              />
            </Box>
          </Box>
        </>
      )}

      <ConfirmationDialog
        title="Confirmation"
        open={confirmDialogOpen}
        onClose={() => {
          setConfirmDialogOpen(false);
          setProductToDelete(null);
        }}
        onAgree={() => handleConfirm()}
      >
        <Typography>
          You are about to delete the product <b>"{productToDelete?.name}"</b>. Do you wish to continue?
        </Typography>
      </ConfirmationDialog>
    </Box>
  );
};
