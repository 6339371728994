import { Box, Button, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';

export interface CheckoutFooterProps {
  onClose?: () => void;
  onContinue?: () => void;
  onNext?: (step) => void;
  onBack?: (step) => void;
  step?: number;
  disabled?: boolean;
  text?: string;
  maxSteps?: number;
}

export const CheckoutFooterComponent: React.FC<CheckoutFooterProps> = ({
  onClose,
  onContinue,
  step,
  maxSteps,
  onBack,
  onNext,
  disabled,
  text,
}) => {
  const [currentStep, setCurrentStep] = useState<number>(step || 0);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box sx={{ textAlign: 'end' }}>
      <Button
        variant="outlined"
        color="primary"
        sx={{ marginTop: 'var(--content-spacing-narrow)', mr: 2 }}
        onClick={() => {
          onClose?.();
        }}
      >
        Cancel
      </Button>
      {onNext && currentStep < maxSteps && (
        <Button
          variant="outlined"
          sx={{ marginTop: 'var(--content-spacing-narrow)', mr: 2 }}
          onClick={() => {
            setCurrentStep(currentStep + 1);
            onNext?.(currentStep + 1);
          }}
        >
          Next
        </Button>
      )}
      {onBack && currentStep > 0 && (
        <Button
          variant="outlined"
          sx={{ marginTop: 'var(--content-spacing-narrow)', mr: 2 }}
          onClick={() => {
            setCurrentStep(currentStep - 1);
            onBack?.(currentStep - 1);
          }}
        >
          Back
        </Button>
      )}
      <Tooltip title={text}>
        <span>
          <Button
            variant="contained"
            color="primary"
            sx={{ marginTop: 'var(--content-spacing-narrow)' }}
            disabled={disabled}
            onClick={() => {
              onContinue?.();
            }}
          >
            {isMobile ? 'Submit' : 'Submit for Quote'}
          </Button>
        </span>
      </Tooltip>
    </Box>
  );
};
