import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import React from 'react';

export interface ConfirmationDialogProps {
  open?: boolean;
  title: string;
  children?: React.ReactNode;
  onClose?: () => void;
  onAgree?: () => void;
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  title,
  children,
  onAgree,
  onClose,
}) => {
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{children}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={onAgree} autoFocus variant="contained">
            OK, Continue
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
