import { Box, CircularProgress, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import ResultConfigurationForm from '../../Components/ResultConfigurationForm';
import { ContentTileComponent } from '../../Components/ContentTitle';
import { useEffect, useState } from 'react';
import { IResult } from '../../core/interfaces/result.interface';
import useGentian from '../../core/hookies/gentian.hook';
import { ResultPreview } from './result-preview.fragment';
import { Map } from 'mapbox-gl';
import { ProjectSelectionList } from '../../Components/ProjectSelectionList';
import { Cancel } from '@mui/icons-material';
import { IProject } from '../../core/interfaces/project.interface';

export const ResultUploadPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [map, setMap] = useState<Map>();

  const { getAllProjects, createResult, publishResult, updateProjectCode } = useGentian();

  const [result, setResult] = useState<IResult>();
  const [projects, setProjects] = useState<any>([]);
  const [isSaving, setIsSaving] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [projectToEdit, setProjectToEdit] = useState<any>(null);
  const [filterStatus, setFilterStatus] = useState<'no-result' | 'has-result'>('no-result');
  const [isLoading, setIsLoading] = useState(true);

  const {
    data: allProjects,
    isLoading: loadingResults,
    isFetched,
    refetch,
  } = getAllProjects({ status: filterStatus });

  const onSave = async (result: IResult) => {
    const image = map?.getCanvas().toDataURL('image/png');
    result.thumbnail = image;
    result.status = 'on_going';
    setIsSaving(true);
    await createResult.mutate(result, {
      onSuccess: () => {
        setIsSaving(false);
        alert('Result saved successfully');
      },
      onError: (error) => {
        setIsSaving(false);
        alert('Error saving result');
        console.error(error);
      },
    });
  };

  const handleProjectEdit = async (project: IProject) => {
    setIsSaving(true);

    await updateProjectCode.mutate(
      { projectId: project._id, code: project.code },
      {
        onSuccess: async () => {
          setIsSaving(false);
          alert('Project code updated successfully');
          setIsLoading(true);
          await refetch();
          setIsLoading(false);
        },
        onError: (error) => {
          setIsSaving(false);
          alert('Error updating project code');
          console.error(error);
        },
      },
    );
  };

  const onPublish = async (result: IResult) => {
    const isConfirmed = window.confirm('Are you sure you want to publish this result?');
    if (!isConfirmed) return;

    result.status = 'published';
    setIsPublishing(true);
    await publishResult.mutate(result, {
      onSuccess: () => {
        setIsPublishing(false);
        alert('Result published successfully');
      },
      onError: (error) => {
        setIsPublishing(false);
        alert('Error publishing result');
        console.error(error);
      },
    });
  };

  useEffect(() => {
    if (!loadingResults) {
      setIsLoading(false);
    }
  }, [loadingResults]);

  useEffect(() => {
    if (allProjects?.length) {
      setProjects(allProjects);
    }
  }, [isFetched, allProjects]);

  return isLoading ? (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress />
      <Typography variant="body1">Loading projects...</Typography>
    </Box>
  ) : !projectToEdit ? (
    <>
      {isSaving ? (
        <Box
          sx={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
          <Typography variant="body1">Updating project code...</Typography>
        </Box>
      ) : (
        <>
          <ContentTileComponent title="Select a project to configure the results" />
          <ProjectSelectionList
            projects={projects}
            filterStatus={filterStatus}
            onAddResultProject={setProjectToEdit}
            onSelectProject={setProjectToEdit}
            onEditProject={handleProjectEdit}
            onFilterChange={(filter) => {
              setFilterStatus(filter);
              setIsLoading(true);
              setTimeout(async () => {
                await refetch();
                setIsLoading(false);
              }, 0);
            }}
          />
        </>
      )}
    </>
  ) : (
    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
      <Box
        sx={{
          width: isMobile ? '100vw' : '320px',
          minWidth: '320px',
          height: 'calc(100vh - var(--header-height) - var(--footer-height))',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <ContentTileComponent title="Configure results" />
          <IconButton onClick={() => setProjectToEdit(null)} sx={{ ml: 'auto' }}>
            <Cancel />
          </IconButton>
        </Box>

        <Box
          sx={{
            overflowY: 'scroll',
            height: 'calc(100vh - var(--header-height) - 80px - var(--footer-height))',
            p: 'var(--content-spacing-narrow)',
          }}
        >
          <ResultConfigurationForm
            onSave={onSave}
            onPublish={onPublish}
            onUpdate={(result) => {
              setResult(result);
            }}
            project={projectToEdit}
          />
        </Box>
      </Box>
      {!isMobile && (
        <ResultPreview
          result={result}
          isSaving={isSaving}
          isPublishing={isPublishing}
          onMapLoad={setMap}
          project={projectToEdit}
        />
      )}
    </Box>
  );
};
