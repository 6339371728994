import { Box, Typography, Divider, Card } from '@mui/material';
import React from 'react';
import { FindOutMoreOverviewFragment } from './overview.fragment';
import BNGAssessment from './assets/bng_assessment_418_380.png';
import HabitatMapping from './assets/habitat_mapping_418_380.png';
import HabitatMappingLegend from './assets/habitat_mapping_legend_265_210.png';
import BNGAssessmentLegend from './assets/bng_assessment_legend_265_210.png';
import SatelliteView from './assets/satellite_view_420_380.png';

export const HabitatMappingFragment = () => {
  return (
    <Box
      className="find-out-more-content"
      sx={{
        display: 'flex',
        direction: 'row',
        m: 'var(--content-spacing)',
        pl: 'var(--content-spacing)',
        pr: 'var(--content-spacing)',
      }}
    >
      <Box sx={{ m: '0 var(--content-spacing) 0 0' }}>
        <Typography variant="h4" sx={{ p: 0, fontWeight: 'bold', color: '#545454' }}>
          Habitat Mapping
        </Typography>
        <Divider sx={{ m: 'var(--content-spacing-narrow) 0 var(--content-spacing-narrow) 0' }} />
        <Typography sx={{ mt: 2 }}>
          <b>What is a Gentian Habitat Map?</b>
        </Typography>
        <Typography
          variant="body1"
          sx={{ m: 'var(--content-spacing-narrow) 0 var(--content-spacing-narrow) 0' }}
        >
          Our habitat mapping solution categorises a given area into a comprehensive map of habitat types.
          Habitat types are based on UKHab, EUNIS or IUCN habitat classification, as requested. Habitat
          mapping can be used to determine an ecological baseline and track and monitor changes through time.{' '}
        </Typography>
        <Typography sx={{ mt: 2 }}>
          <b>Can a Gentian Habitat Map be used for Biodiversity Net Gain (BNG)?</b>
        </Typography>
        <Typography
          variant="body1"
          sx={{ m: 'var(--content-spacing-narrow) 0 var(--content-spacing-narrow) 0' }}
        >
          Yes. Our remote Biodiversity Net Gain (BNG) assessment seamlessly translates the UKHab classes into
          habitat distinctiveness and condition to compute a BNG score for a site.
        </Typography>
        <Typography
          variant="body1"
          sx={{ m: 'var(--content-spacing-narrow) 0 var(--content-spacing-narrow) 0' }}
        >
          A BNG assessment can be used to determine a biodiversity baseline and track and monitor changes
          through time.
        </Typography>
        <Typography sx={{ mt: 2 }}>
          <b>What does a Gentian Habitat Map include?</b>
        </Typography>
        <Typography
          variant="body1"
          sx={{ m: 'var(--content-spacing-narrow) 0 var(--content-spacing-narrow) 0' }}
        >
          Results are provided in the form of digital maps, spreadsheets and a report.
        </Typography>
        <Typography sx={{ mb: 2, fontWeight: 'bold' }}>
          Download a{' '}
          <a href="/habitat-mapping-and-bng-case-study.pdf" download>
            case study
          </a>
        </Typography>
        <Card
          sx={{
            p: 'var(--content-spacing-narrow)',
            maxWidth: '800px',
            mb: 'var(--content-spacing-narrow)',
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: 'bold',
              fontSize: '14px',
              color: '#545454',
            }}
          >
            Example Habitat Map
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              maxWidth: '800px',
              alignItems: 'center',
            }}
          >
            <img
              src={SatelliteView}
              style={{
                maxWidth: '260px',
                maxHeight: '210px',
              }}
            />
            <img
              src={HabitatMapping}
              style={{
                maxWidth: '260px',
                maxHeight: '210px',
              }}
            />
            <img src={HabitatMappingLegend} style={{ width: '195px', aspectRatio: 'auto' }} />
          </Box>
        </Card>
        <Card
          sx={{
            p: 'var(--content-spacing-narrow)',
            maxWidth: '800px',
            mb: 'var(--content-spacing)',
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: 'bold',
              fontSize: '14px',
              color: '#545454',
            }}
          >
            Example BNG Assessment
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              maxWidth: '800px',
              alignItems: 'center',
            }}
          >
            <img
              src={SatelliteView}
              style={{
                maxWidth: '260px',
                maxHeight: '210px',
              }}
            />
            <img
              src={BNGAssessment}
              style={{
                maxWidth: '260px',
                maxHeight: '210px',
              }}
            />
            <Box sx={{ width: '195px' }}>
              <img src={BNGAssessmentLegend} style={{ width: '140px', aspectRatio: 'auto' }} />
            </Box>
          </Box>
        </Card>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 'bold',
            fontSize: '18px',
            color: '#545454',
            mt: 'var(--content-spacing)',
            mb: 'var(--content-spacing)',
          }}
        >
          Products Overview
        </Typography>
        <FindOutMoreOverviewFragment />
      </Box>
    </Box>
  );
};
