import { Box, Divider, IconButton, Typography, styled } from '@mui/material';
import React, { useEffect } from 'react';
import * as turf from '@turf/turf';
import mapboxgl from 'mapbox-gl';
import { ChangeCircle } from '@mui/icons-material';
import { Root, createRoot } from 'react-dom/client';

export interface AreaCalculationControlProps {
  shape?: GeoJSON.FeatureCollection;
}

export class AreaCalculationControl implements mapboxgl.IControl {
  private container: HTMLElement;
  private shape?: GeoJSON.FeatureCollection;
  private root: Root;

  constructor({ shape }: AreaCalculationControlProps = {}) {
    this.shape = shape;
    this.container = document.createElement('div');
    this.container.className = 'mapboxgl-ctrl';
    this.root = createRoot(this.container);

    this.render();
  }

  onAdd(): HTMLElement {
    return this.container;
  }

  onRemove(): void {
    this.container.parentNode.removeChild(this.container);
  }

  getDefaultPosition(): string {
    return 'bottom-right';
  }

  update(shape: GeoJSON.FeatureCollection) {
    this.shape = shape;
    this.render();
  }

  render() {
    this.root.render(<AreaCalculationComponent shape={this.shape} />);
  }
}

const AreaContainer = styled(Box)({
  backgroundColor: 'white',
  borderRadius: '4px',
  padding: '4px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

const TotalAreaText = styled(Typography)({
  fontSize: '14px',
  margin: '0px 8px 0px 8px',
  minWidth: '160px',
});

export const AreaCalculationComponent: React.FC<AreaCalculationControlProps> = ({ shape }) => {
  const [unit, setUnit] = React.useState<'ha' | 'km²' | 'acres'>('ha');
  const [area, setArea] = React.useState<number>(0);

  useEffect(() => {
    if (shape?.features?.length) {
      const m2Area = turf.area(shape);
      const km2Area = m2Area / 1e6;
      const conversionFactor = unit === 'ha' ? 100 : unit === 'acres' ? 247.105 : 1;
      const convertedArea = km2Area * conversionFactor;
      const rounded_area = Math.round(convertedArea * 100) / 100;

      setArea(rounded_area);
    } else {
      setArea(0);
    }
  }, [shape, unit]);

  const onUnitChange = () => {
    if (unit === 'ha') {
      setUnit('km²');
      setArea(area / 100);
    } else if (unit === 'km²') {
      setUnit('acres');
      setArea(area * 247.105);
    } else {
      setUnit('ha');
      setArea(area * 100);
    }
  };

  return (
    <AreaContainer id="map-area-calculation-control">
      <TotalAreaText>Total Area: {`${area} ${unit}`}</TotalAreaText>
      <Divider orientation="vertical" style={{ height: '24px' }} />
      <IconButton onClick={() => onUnitChange()}>
        <ChangeCircle />
        <Typography fontSize={10} marginLeft={'8px'}>
          ha/km²/acres
        </Typography>
      </IconButton>
    </AreaContainer>
  );
};
