import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import './style.scss';

import mapboxgl, {
  FullscreenControl,
  GeoJSONSource,
  GeolocateControl,
  Map,
  NavigationControl,
} from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import * as turf from '@turf/turf';

import React, { useEffect, useRef } from 'react';
import { SxProps, styled, useMediaQuery, useTheme } from '@mui/material';
import { AreaCalculationControl } from './AreaCalculationControl';
import { MAP_STYLES, MapStyleSwitchControl } from './MapStyleSwitchControl';
import { MapWalkThroughComponent } from './WalkThrough';
import { PointEditControl } from './EditPointControl';
import { ResetControl } from './ResetControl';
import { GoogleSearchControl } from './GoogleSearchControl';
import CustomPolygonMode from './CustomPolygonMode';
import StaticMode from '@mapbox/mapbox-gl-draw-static-mode';
import { datadogLogs } from '@datadog/browser-logs';

const MAP_CONTAINER_ID = 'map_container';

const MapContainer = styled('div')({});

export interface MapProps {
  accessToken: string;
  onShapeDrawn?: (shape: GeoJSON.FeatureCollection) => void;
  onLoad?: (map: Map) => void;
  onMouseEnter?: (map: Map, e: any) => void;
  initialShape?: GeoJSON.FeatureCollection;
  filteredLayers?: { id: string; visible: boolean }[];
  readOnly?: boolean;
  sx?: SxProps;
  hideMapStyleSwitchControl?: boolean;
  sources?: any;
  layers?: any;
  patterns?: any;
  style?: MAP_STYLES;
}

export const MapComponent: React.FC<MapProps> = ({
  accessToken,
  initialShape,
  readOnly,
  onShapeDrawn,
  onLoad,
  onMouseEnter,
  filteredLayers,
  sx,
  hideMapStyleSwitchControl,
  sources,
  layers,
  patterns,
  style,
}) => {
  mapboxgl.accessToken = accessToken;

  const map = useRef<Map | null>(null);
  const mapContainer = useRef(null);
  const drawControl = useRef<MapboxDraw | null>(null);
  const areaCalculationControl = useRef<AreaCalculationControl | null>(null);

  const currentFeature = React.useRef<GeoJSON.FeatureCollection>(turf.featureCollection([]));
  const [history, setHistory] = React.useState<GeoJSON.FeatureCollection[]>([]);
  const [redoHistory, setRedoHistory] = React.useState<GeoJSON.FeatureCollection[]>([]);
  const [loadDone, setLoadDone] = React.useState<boolean>(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const updateHistory = (featureCollection: GeoJSON.FeatureCollection) => {
    history.push(currentFeature.current);
    if (history.length > 1000) {
      history.shift();
    }
    setHistory(history);
    redoHistory.splice(0, redoHistory.length);
    setRedoHistory(redoHistory);
    currentFeature.current = featureCollection;
  };

  const updateShape = () => {
    const shapeInfo = drawControl.current?.getAll();
    areaCalculationControl.current.update(shapeInfo);

    onShapeDrawn?.(shapeInfo);
  };

  const keydownListener = (e: KeyboardEvent) => {
    if (e.key === 'z' && e.ctrlKey && !e.shiftKey) {
      if (history.length) {
        const feature = history.pop();
        setHistory(history);
        redoHistory.push(currentFeature.current);
        setRedoHistory(redoHistory);
        currentFeature.current = feature;
        drawControl.current?.deleteAll();
        setTimeout(() => {
          drawControl.current?.set(currentFeature.current);
          updateShape();
        }, 0);
      }
    }

    if (e.key?.toLowerCase() === 'z' && e.ctrlKey && e.shiftKey) {
      if (redoHistory.length) {
        const feature = redoHistory.pop();
        setRedoHistory(redoHistory);
        history.push(currentFeature.current);
        setHistory(history);
        currentFeature.current = feature;
        drawControl.current?.deleteAll();
        setTimeout(() => {
          drawControl.current?.set(currentFeature.current);
          updateShape();
        }, 0);
      }
    }
  };

  useEffect(() => {
    if (!map.current) {
      const navigationControl = new NavigationControl({ showCompass: false });
      const searchControl = new GoogleSearchControl();
      const mapStyleSwitchControl = new MapStyleSwitchControl();
      const fullScreeControl = new FullscreenControl({ container: document.querySelector('body') });
      const geolocateControl = new GeolocateControl({
        showAccuracyCircle: false,
        trackUserLocation: false,
      });

      areaCalculationControl.current = new AreaCalculationControl();
      drawControl.current = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          polygon: !readOnly,
          combine_features: false,
          uncombine_features: false,
          trash: !readOnly,
        },
        modes: Object.assign(
          {
            custom_polygon: CustomPolygonMode,
            static: StaticMode,
          },
          MapboxDraw.modes,
        ) as any,
      });

      const editPointControl = new PointEditControl(drawControl.current);
      const resetControl = new ResetControl(drawControl.current, areaCalculationControl.current);

      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: !!style ? style : readOnly ? MAP_STYLES.STREETS : MAP_STYLES.SATELLITE_STREETS,
        center: [0.1276, 51.5072],
        dragRotate: false,
      }).addControl(searchControl, 'top-left');

      if (!isMobile) {
        map.current.addControl(navigationControl, 'top-left');
        map.current.addControl(fullScreeControl, 'top-right');
      }

      if (!hideMapStyleSwitchControl) {
        map.current.addControl(mapStyleSwitchControl, 'bottom-left');
      }

      if (!readOnly) {
        map.current
          .addControl(resetControl, 'bottom-left')
          .addControl(areaCalculationControl.current, isMobile ? 'top-left' : 'bottom-right')
          .addControl(new mapboxgl.ScaleControl(), 'bottom-right')
          .addControl(geolocateControl, isMobile ? 'top-left' : 'top-right');
      }

      map.current.addControl(drawControl.current, 'bottom-left');

      if (!readOnly) {
        map.current.addControl(editPointControl, 'bottom-left');
      }

      map.current.on('load', () => {
        if (!initialShape) {
          geolocateControl.trigger();
        }
        if (readOnly) {
          drawControl.current.changeMode('static');
        }

        onLoad?.(map.current as Map);
        setLoadDone(true);
      });

      map.current.on('draw.create', (evt) => {
        updateHistory(turf.featureCollection(evt.features));
        updateShape();
      });
      map.current.on('draw.delete', (evt) => {
        updateHistory(turf.featureCollection(evt.features));
        updateShape();
      });
      map.current.on('draw.update', (evt) => {
        updateHistory(turf.featureCollection(evt.features));
        updateShape();
      });

      document.addEventListener('keydown', keydownListener);
    }
  }, [accessToken, onShapeDrawn]);

  useEffect(() => {
    if (initialShape?.features?.length) {
      map.current.stop();
      setTimeout(() => {
        if (readOnly) {
          drawControl.current.set(initialShape);
        } else {
          drawControl.current.add(initialShape);
        }
        const shapeInfo = drawControl.current?.getAll();
        currentFeature.current = shapeInfo;
        areaCalculationControl.current.update(shapeInfo);
        const bbox = turf.bbox(shapeInfo);
        map.current.fitBounds(bbox as any, { padding: 50, maxZoom: 17 });
      }, 300);
    } else {
      drawControl.current?.deleteAll();
    }
  }, [initialShape]);

  useEffect(() => {
    if (map.current && loadDone && filteredLayers) {
      filteredLayers.forEach((layer) => {
        map.current.setLayoutProperty(layer.id, 'visibility', layer.visible ? 'visible' : 'none');
      });
    }
  }, [filteredLayers]);

  useEffect(() => {
    if (!loadDone) return;

    if (!sources?.length) return;

    sources?.forEach((source) => {
      const foundSource = map.current.getSource(source.id);
      if (!foundSource) {
        map.current.addSource(source.id, source.data);
        map.current.on('mouseenter', source.id, (e) => {
          onMouseEnter?.(map.current, e as any);
        });
      } else {
        if (foundSource.type === 'geojson') {
          (foundSource as GeoJSONSource).setData(source.data.data);
        }
      }
    });
  }, [loadDone, sources]);

  useEffect(() => {
    if (!loadDone) return;
    if (!sources?.length || !layers?.length) return;

    if (!map.current.getSource(sources[0].id)) return;

    layers?.forEach((layer) => {
      if (map.current.getLayer(layer.id)) {
        map.current.removeLayer(layer.id);
      }
      map.current.addLayer(layer);
    });
  }, [layers]);

  useEffect(() => {
    if (!loadDone) return;
    if (!patterns) return;

    Object.entries(patterns).forEach(([id, pattern]) => {
      map.current.loadImage(pattern as string, (error, image) => {
        if (error) {
          datadogLogs.logger.error('Error loading image', {
            error,
            name: 'loadImage',
            id,
            pattern,
          });
          throw error;
        }
        if (map.current.hasImage(id)) {
          map.current.removeImage(id);
        }
        map.current.addImage(id, image);
      });
    });
  }, [patterns]);

  return (
    <>
      <MapContainer ref={mapContainer} id={MAP_CONTAINER_ID} sx={{ ...sx }}></MapContainer>
      <MapWalkThroughComponent />
    </>
  );
};
