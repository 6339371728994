import { Avatar, IconButton, Tooltip } from '@mui/material';
import React, { useEffect } from 'react';
import { AvatarMenuComponent, MenuClickTarget } from './Menu';
import { useAuth0 } from '@auth0/auth0-react';
import { useQueryClient } from 'react-query';
import { datadogLogs } from '@datadog/browser-logs';

export interface AvatarProps {
  name: string;
  imageURI?: string;
  showInitials?: boolean;
}

export const AvatarComponent: React.FC<AvatarProps> = ({ name, imageURI, showInitials }) => {
  const { logout, user } = useAuth0();
  const queryClient = useQueryClient();
  const [profilePic, setProfilePic] = React.useState<string | undefined>(imageURI);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (user || imageURI) {
      setProfilePic(imageURI ?? user?.picture);
    }
  }, [user, imageURI]);

  const handleMenuClick = async (target: MenuClickTarget) => {
    if (target === 'LOGOUT') {
      datadogLogs.logger.info('User decided to logging out...');
      datadogLogs.logger.info('Cancelling all queries and mutations...');
      await queryClient.cancelQueries();
      await queryClient.cancelMutations();
      datadogLogs.logger.info('Logging out...');
      await logout({ logoutParams: { returnTo: window.location.origin } });
      datadogLogs.logger.info('Removing auth token from local storage...');
      localStorage.removeItem('gop-auth-token');
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getInitials = (userName: string) => {
    return userName.split(' ').reduce((acc, name) => {
      acc.length ? (acc[1] = name[0]) : acc.push(name[0]);
      return acc;
    }, []);
  };

  return (
    <>
      <Tooltip title="Account settings">
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <Avatar alt={name} src={profilePic}>
            {showInitials && getInitials(name)}
          </Avatar>
        </IconButton>
      </Tooltip>
      <AvatarMenuComponent
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onMenuClick={handleMenuClick}
        onClose={handleClose}
      />
    </>
  );
};
