import 'chart.js/auto';

import { Box, CircularProgress, Divider, IconButton, Switch, TextField, Typography } from '@mui/material';
import { LoadAOIComponent } from '../LoadAOI';
import { IResultLayer } from '../../core/interfaces/result.interface';
import { FC, useEffect, useState } from 'react';
import { FeatureCollection } from '@turf/turf';
import { ChartTabConfiguration } from './ChartTabConfiguration';
import { DetailsConfiguration } from './DetailsConfiguration';
import { buildChartConfig, buildDetailsConfig } from '../../core/builders/layer.builder';
import { DataSourceConfiguration } from './DataSourceConfiguration';
import { Delete, FastForward, FastRewind } from '@mui/icons-material';

export interface LayerConfigProps {
  layer?: IResultLayer;
  index: number;
  last?: boolean;
  first?: boolean;
  onLayerEdit: (index, layer) => void;
  onRemoveLayer?: (index) => void;
  onMoveLayer?: (index, direction) => void;
}

export const LayerConfigComponent: FC<LayerConfigProps> = ({
  layer,
  index,
  last,
  first,
  onLayerEdit,
  onMoveLayer,
  onRemoveLayer,
}) => {
  const [layerData, setLayerData] = useState<IResultLayer>(layer);
  const [featureProperties, setFeatureProperties] = useState<Record<string, any>[]>([]);
  const [labels, setLabels] = useState<string[]>([]);
  const [syncChart, setSyncChart] = useState<boolean>(true);
  const [columns, setColumns] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [displayName, setDisplayName] = useState<string>(layerData?.displayName || '');
  const [imageryUri, setImageryUri] = useState<string>(layerData?.imageryUri || '');

  const [timeoutToken, setTimeoutToken] = useState<NodeJS.Timeout>();

  const [loadedFiles, setLoadedFiles] = useState<string[]>([]);

  const extractProperties = (geojson: GeoJSON.FeatureCollection | GeoJSON.Feature) =>
    (
      (geojson as FeatureCollection)?.features?.map((f) => f.properties) ||
      (geojson as GeoJSON.Feature).properties ||
      []
    ).flat();

  const extractColumns = (properties: Record<string, any>[]) => {
    const columns = [];

    properties?.forEach((p) => {
      Object.keys(p)?.forEach((k) => {
        if (!columns.includes(k)) {
          columns.push(k);
        }
      });
    });

    return columns;
  };

  const onLoadResult = (geojson: GeoJSON.FeatureCollection | GeoJSON.Feature, fileName: string) => {
    setIsLoading(true);
    loadedFiles.includes(fileName) || setLoadedFiles([...loadedFiles, fileName]);

    const properties = [...featureProperties, ...extractProperties(geojson)];
    const columns = extractColumns(properties);

    setFeatureProperties(properties);
    setColumns(columns);

    if (!layerData.data) {
      layerData.data = geojson as any;
      setLayerData({ ...layerData });
      setIsLoading(false);
    } else {
      layerData.data.features = [...layerData.data.features, ...(geojson as any).features];
      const labels = properties
        .filter((p) => p[layerData.details.columnName])
        .map((p) => `${p[layerData.details.columnName]}`)
        .reduce((acc, cur) => (acc.includes(cur) ? acc : [...acc, cur]), []);

      setLabels(labels);
    }
  };

  useEffect(() => {
    if (labels.length) {
      setIsLoading(true);

      if (!layerData.tab.pieChart?.length) {
        layerData.tab.pieChart = [];
      }

      layerData.tab.visible = true;

      buildChartConfig(labels).forEach((seriesConfig) => {
        !layerData.tab.pieChart.find((seriesData) => seriesData.className === seriesConfig.className) &&
          layerData.tab.pieChart.push(seriesConfig);
      });

      layerData.details = buildDetailsConfig(layerData.details.columnName, layerData.tab.pieChart);
      setLayerData({ ...layerData });

      setIsLoading(false);
    }
  }, [labels]);

  useEffect(() => {
    if (timeoutToken) clearTimeout(timeoutToken);

    const token = setTimeout(() => {
      onLayerEdit(index, layerData);
      setTimeoutToken(undefined);
    }, 300);

    setTimeoutToken(token);

    if (!columns?.length && !featureProperties?.length && layerData?.data) {
      setIsLoading(true);

      const properties = extractProperties(layerData?.data);
      const columns = extractColumns(properties);

      setFeatureProperties(properties);
      setColumns(columns);

      setIsLoading(false);
    }
  }, [layerData]);

  return isLoading ? (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Typography variant="body2">Loading...</Typography>
      <CircularProgress />
    </Box>
  ) : (
    <Box sx={{ m: 1, display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
        <IconButton
          disabled={first}
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            onMoveLayer(index, -1);
          }}
          style={{ marginLeft: '8px' }}
        >
          <FastRewind fontSize="small" />
        </IconButton>
        <IconButton
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            onRemoveLayer(index);
          }}
          style={{ marginLeft: '8px' }}
        >
          <Delete fontSize="small" />
        </IconButton>
        <IconButton
          size="small"
          disabled={last}
          onClick={(e) => {
            e.stopPropagation();
            onMoveLayer(index, 1);
          }}
          style={{ marginLeft: '8px' }}
        >
          <FastForward fontSize="small" />
        </IconButton>
      </Box>
      <TextField
        label="Display Name"
        fullWidth
        value={displayName}
        onChange={(e) => {
          setDisplayName(e.target.value);
        }}
        onBlur={() => {
          layerData.displayName = displayName;
          setLayerData({ ...layerData });
        }}
        margin="normal"
      />

      <TextField
        label="Imagery URI"
        fullWidth
        value={imageryUri}
        onChange={(e) => {
          setImageryUri(e.target.value);
        }}
        onBlur={() => {
          layerData.imageryUri = imageryUri;
          setLayerData({ ...layerData });
        }}
        margin="normal"
      />

      <Box sx={{ lineHeight: '56px', display: 'flex', my: 2, flexDirection: 'column' }}>
        <LoadAOIComponent
          onLoadAOI={onLoadResult}
          onError={(error) => console.error('Error loading AOI:', error)}
        />
        {!!loadedFiles.length && (
          <>
            <Typography variant="body1" sx={{ mt: 1 }}>
              Loaded files:
            </Typography>
            {loadedFiles.map((file) => (
              <Typography
                variant="body2"
                sx={{ ml: 1, color: 'gray' }}
                key={`loaded_file_${file.replaceAll(' ', '_')}`}
              >
                • {file}
              </Typography>
            ))}
          </>
        )}
      </Box>
      <Divider sx={{ mb: 1 }} />

      {!!columns?.length && (
        <DataSourceConfiguration
          layer={layerData}
          data={featureProperties}
          onDisplayNameChange={(index, displayName) => {
            layerData.details.infoColumns[index].displayName = displayName;
            setLayerData({ ...layerData });
          }}
          onLabelSourceChange={(col) => {
            const labels = featureProperties
              .filter((p) => p[col])
              .map((p) => p[col])
              .reduce((acc, cur) => (acc.includes(cur) ? acc : [...acc, cur]), []);

            setLabels(labels);

            layerData.details.columnName = col;
            setLayerData({ ...layerData });
          }}
          onDataSourceChange={(index, col) => {
            layerData.details.infoColumns[index].dataColumn = col;
            let total = 0;
            const values = featureProperties.reduce((acc, prop) => {
              const label = prop[layerData.details.columnName];
              const value = prop[col] || 0;

              if (!acc[label]) {
                acc[label] = value;
              } else {
                acc[label] += value;
              }

              total += +value;
              return acc;
            }, {});

            Object.entries(values).forEach(([k, v]) => {
              const value = Math.round(v * 100) / 100;
              const classIndex = layerData.details.classes.findIndex((c) => c.category == k);

              layerData.details.classes[classIndex].infoValues[index] = value;

              if (index === 0 && !layerData?.details?.infoColumns[1]?.dataColumn) {
                const percentage = Math.round((value / total) * 1000) / 10;
                layerData.details.classes[classIndex].infoValues[1] = percentage;
                layerData.tab.pieChart[classIndex].percent = percentage;
                layerData.tab.visible = true;
              }
              if (index > 0) {
                layerData.tab.pieChart[classIndex].percent = value;
              }
            });

            setLayerData({ ...layerData });
          }}
          onDisplayInInspectorChange={(displayName, visible) => {
            if (visible) {
              if (!layerData.inspector.columnsToDisplay.includes(displayName)) {
                layerData.inspector.columnsToDisplay.push(displayName);
              }
            } else {
              if (layerData.inspector.columnsToDisplay.includes(displayName)) {
                layerData.inspector.columnsToDisplay = layerData.inspector.columnsToDisplay.filter(
                  (c) => c !== displayName,
                );
              }
            }
            setLayerData({ ...layerData });
          }}
          onDisplayInLayerPanelChange={(index, visible) => {
            layerData.details.infoColumns[index].visible = visible;
            setLayerData({ ...layerData });
          }}
        />
      )}

      {!!layerData?.tab?.pieChart?.length && (
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <label htmlFor="chart-visibility" style={{ fontSize: '14px' }}>
              Sync chart and layer
            </label>
            <Switch
              checked={syncChart}
              onChange={(e) => {
                setSyncChart(e.target.checked);
              }}
            />
          </Box>

          <Typography variant="body2" fontSize="10px">
            <i>
              All configurations related to colors and patterns applied to the chart will be synched to the
              layers. But changes made in the layers will not be reflected in the chart.
            </i>
          </Typography>
          <Divider sx={{ mb: 1, mt: 1 }} />
          <ChartTabConfiguration
            layer={layerData}
            onLayerEdit={(data: IResultLayer) => {
              if (syncChart) {
                data?.details?.classes?.forEach((c, i) => {
                  c.fillPattern = data.tab.pieChart[i].fillPattern;
                  c.fillTransparency = data.tab.pieChart[i].fillTransparency;
                  c.fillColor = data.tab.pieChart[i].fillColor;
                  c.displayName = data.tab.pieChart[i].className;
                });
              }
              setLayerData({ ...data });
            }}
          />
          <Divider sx={{ mb: 1, mt: 1 }} />
        </>
      )}

      {!!layerData?.details?.classes?.length && (
        <>
          <DetailsConfiguration layer={layerData} onLayerEdit={setLayerData} />
          <Divider sx={{ mb: 1 }} />
        </>
      )}
    </Box>
  );
};
