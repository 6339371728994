export enum ChartPattern {
  None = '<none>',
  Plus = 'plus',
  Cross = 'cross',
  Dash = 'dash',
  CrossDash = 'cross-dash',
  Dot = 'dot',
  DotDash = 'dot-dash',
  Disc = 'disc',
  Ring = 'ring',
  Line = 'line',
  LineVertical = 'line-vertical',
  Weave = 'weave',
  Zigzag = 'zigzag',
  ZigzagVertical = 'zigzag-vertical',
  Diagonal = 'diagonal',
  DiagonalRightLeft = 'diagonal-right-left',
  Square = 'square',
  Box = 'box',
  Triangle = 'triangle',
  TriangleInverted = 'triangle-inverted',
  Diamond = 'diamond',
  DiamondBox = 'diamond-box',
  Undefined = 'undefined',
}
