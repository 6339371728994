import { Box, TextField, styled } from '@mui/material';
import { Autocomplete } from '@react-google-maps/api';
import mapboxgl from 'mapbox-gl';
import { useRef, useState } from 'react';
import { Root, createRoot } from 'react-dom/client';

function extractLatLng(latLongString: string): { lat: number; lng: number } | undefined {
  // Split the string into latitude and longitude components
  const parts = latLongString.split(',');

  // Check if we have exactly two parts
  if (parts.length !== 2) return undefined;

  // Parse latitude and longitude as floats
  const lat = parseFloat(parts[0].trim());
  const lng = parseFloat(parts[1].trim());

  // Check if latitude and longitude are valid numbers
  if (isNaN(lat) || isNaN(lng)) return undefined;

  // Validate the latitude and longitude ranges
  const isValidLatitude = lat >= -90 && lat <= 90;
  const isValidLongitude = lng >= -180 && lng <= 180;

  return isValidLatitude && isValidLongitude ? { lat, lng } : undefined;
}

export class GoogleSearchControl implements mapboxgl.IControl {
  private container: HTMLElement;
  private root: Root;
  private map: mapboxgl.Map;

  constructor() {
    this.container = document.createElement('div');
    this.container.className = 'mapboxgl-ctrl';
    this.root = createRoot(this.container);

    this.render();
  }

  render() {
    this.root.render(
      <GoogleSearchComponent
        onPlaceChange={(point) => {
          if (!point?.lng || !point?.lat || isNaN(point?.lat) || isNaN(point?.lng)) return;
          this.map.flyTo({ center: [point.lng, point.lat], zoom: 14 });
        }}
      />,
    );
  }

  onAdd(map) {
    this.map = map;
    return this.container;
  }

  onRemove() {
    this.container.parentNode.removeChild(this.container);
    this.map = undefined;
  }
}

const ControlContainer = styled(Box)({
  backgroundColor: 'white',
  borderRadius: '4px',
  maxWidth: '320px',
  width: '75vw',
});

export const GoogleSearchComponent: React.FC<{
  onPlaceChange: (point: { lng: number; lat: number }) => void;
}> = ({ onPlaceChange }) => {
  const [autocomplete, setAutocomplete] = useState<google.maps.places.Autocomplete>();
  const inputRef = useRef<HTMLInputElement>(null);

  const onLoaded = (autocomplete: google.maps.places.Autocomplete) => {
    setAutocomplete(autocomplete);
  };

  const onPlaceChanged = () => {
    const place = autocomplete.getPlace();

    if (!place?.geometry?.location) {
      onPlaceChange?.(extractLatLng(place?.name));
    }
    onPlaceChange?.({ lng: place?.geometry?.location?.lng(), lat: place?.geometry?.location?.lat() });
  };

  return (
    <ControlContainer id="map-search-address-control">
      <Autocomplete onLoad={onLoaded} onPlaceChanged={onPlaceChanged}>
        <TextField
          type="text"
          placeholder="Search an address"
          ref={inputRef}
          size="small"
          fullWidth
          sx={{
            boxShadow: '0 2px 4px 0 rgba(0,0,0,0.2)',
            borderRadius: '4px',
          }}
        />
      </Autocomplete>
    </ControlContainer>
  );
};
