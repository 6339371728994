import { IProduct } from '../interfaces/product.interface';

export class Product implements IProduct {
  public _id;
  public name;
  public shortDescription;
  public image;
  public detailsURI;
  public enabled;
  public size;

  constructor({ _id, name, shortDescription, image, detailsURI, enabled, size }: Partial<IProduct> = {}) {
    this._id = _id || '';
    this.name = name || '';
    this.shortDescription = shortDescription || '';
    this.image = image || '';
    this.detailsURI = detailsURI || '';
    this.enabled = enabled === true ? true : false;
    this.size = size || 'small';
  }
}
