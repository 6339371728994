import { Typography } from '@mui/material';
import React from 'react';

const spriteWidth = 1278; // Total width of the sprite image
const spriteHeight = 1069; // Total height of the sprite image

const ErrorPage: React.FC = () => {
  const spriteHIndex = Math.floor(Math.random() * 3);
  const spriteVIndex = Math.floor(Math.random() * 3);
  const spriteX = (-spriteWidth / 3) * spriteHIndex;
  const spriteY = (-spriteHeight / 3) * spriteVIndex;

  const spriteStyle = {
    width: '430px',
    height: '356px',
    backgroundImage: `url(error-icon.sprite.png)`,
    backgroundPosition: `${spriteX}px ${spriteY}px`,
    margin: '0 auto',
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center',
        backgroundColor: 'white',
      }}
    >
      <Typography variant="h3">Oh no! Something went wrong.</Typography>
      <Typography variant="h4">Try again in a few minutes.</Typography>
      <div style={spriteStyle}></div>
      <Typography variant="body1">
        If the problem persists, please contact us at <a href="mailto:info@gentian.team">info@gentian.team</a>
      </Typography>
    </div>
  );
};

export default ErrorPage;
