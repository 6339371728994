import React, { useRef, useState } from 'react';
import { DataGrid, GridColDef, GridValueFormatterParams } from '@mui/x-data-grid';
import { IProject } from '../../core/interfaces/project.interface';
import { Edit, OpenInNew, ThumbDownAlt, ThumbUpAlt } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { ProjectUpdateModal } from '../ProjectUpdateModal';

export interface ProjectSelectionListProps {
  projects: IProject[];
  filterStatus?: 'no-result' | 'has-result';
  onSelectProject?: (project: IProject) => void;
  onEditProject?: (project: IProject) => void;
  onAddResultProject?: (project: IProject) => void;
  onFilterChange?: (filter: 'no-result' | 'has-result') => void;
}

export const ProjectSelectionList: React.FC<ProjectSelectionListProps> = ({
  projects,
  filterStatus,
  onAddResultProject,
  onSelectProject,
  onEditProject,
  onFilterChange,
}) => {
  const [searchText, setSearchText] = useState('');
  const [filter, setFilter] = useState<'no-result' | 'has-result'>(filterStatus || 'no-result');
  const [codeEditModalOpen, setCodeEditModalOpen] = useState(false);
  const [project, setProject] = useState<IProject>(undefined);

  const columns = useRef<GridColDef[]>();

  columns.current = [
    {
      field: 'id',
      headerName: 'Id',
      headerAlign: 'center',
    },
    {
      field: 'AOIThumbnail',
      headerAlign: 'center',
      headerName: '',
      sortable: false,
      groupable: false,
      filterable: false,
      hideable: false,
      renderCell: (params) => (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <img
            src={params.value}
            style={{
              maxWidth: '80px',
              maxHeight: '42px',
              objectFit: 'cover',
              borderRadius: '4px',
            }}
          />
        </Box>
      ),
    },
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 128,
      headerAlign: 'center',
      flex: 1,
    },
    {
      field: 'code',
      headerName: 'Project Code',
      width: 180,
      renderCell: (params) => (
        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', alignItems: 'center' }}>
          <Typography variant="body2">{params.value || 'No Code'}</Typography>
          <IconButton
            size="small"
            onClick={() => {
              setProject(params.row);
              setCodeEditModalOpen(true);
            }}
          >
            <Edit />
          </IconButton>
        </Box>
      ),
      headerAlign: 'center',
    },
    {
      field: 'requestDate',
      headerName: 'Request Date',
      maxWidth: 160,
      minWidth: 96,
      align: 'center',
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams) => new Date(params.value).toLocaleDateString(),
      headerAlign: 'center',
    },
    {
      field: 'hasResult',
      headerName: 'Has Result',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      renderCell: (params) =>
        params.row.hasResult ? <ThumbUpAlt color="success" /> : <ThumbDownAlt color="warning" />,
    },
    {
      field: 'result',
      headerName: 'Result',
      width: 180,
      renderCell: (params) =>
        params.row.hasResult ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Button
              variant="contained"
              sx={{ backgroundColor: 'var(--gentian-tertiary)', color: 'white' }}
              onClick={() => onSelectProject(params.row)}
            >
              Edit Result <OpenInNew />
            </Button>
          </Box>
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <Button
              variant="contained"
              sx={{ backgroundColor: 'var(--gentian-primary)', color: 'white' }}
              onClick={() => onAddResultProject(params.row)}
            >
              Add Result <OpenInNew />
            </Button>
          </Box>
        ),
      headerAlign: 'center',
    },
  ];

  const handleSearchChange = (event) => {
    setSearchText(event.target.value.toString());
  };

  const filteredRows = projects
    .map((r) => ({ ...r, download: r.AOI, open: r.AOI, id: r._id, result: r._id }))
    .filter(
      (row) =>
        row.name.toLowerCase().includes(searchText.toLowerCase()) ||
        new Date(row.requestDate).toLocaleDateString().includes(searchText),
    );

  return (
    <Box sx={{ padding: 2 }}>
      {codeEditModalOpen && (
        <ProjectUpdateModal
          field="code"
          project={project}
          onClose={() => setCodeEditModalOpen(false)}
          onSaveClick={(value) => {
            project.code = value;
            onEditProject?.(project);
            setCodeEditModalOpen(false);
          }}
        />
      )}
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <TextField
          value={searchText}
          onChange={handleSearchChange}
          placeholder="Search by name or date"
          variant="standard"
          sx={{ width: '256px', my: 2 }}
        />
        <ToggleButtonGroup
          sx={{ ml: 4, mb: 1 }}
          exclusive
          value={filter}
          onChange={(_, value) => {
            setFilter(value);
            onFilterChange?.(value);
          }}
        >
          <ToggleButton value="no-result">Without Result</ToggleButton>
          <ToggleButton value="has-result">Has Result</ToggleButton>
        </ToggleButtonGroup>
      </Box>
      <DataGrid
        rows={filteredRows}
        columns={columns.current}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 20,
            },
          },
          sorting: {
            sortModel: [
              {
                field: 'requestDate',
                sort: 'desc',
              },
            ],
          },
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
        }}
        pageSizeOptions={[5, 10, 20, 50, 100]}
        disableRowSelectionOnClick
      />
    </Box>
  );
};
