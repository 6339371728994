import { Typography, Box, Link } from '@mui/material';
import React from 'react';
import styled from '@emotion/styled';

const StyledList = styled('ul')({
  listStyle: 'none',
  padding: 0,
  margin: 0,
  display: 'inline-flex',
  alignItems: 'center',
});

const StyledListItem = styled('li')({
  marginRight: '20px',
  marginLeft: '20px',
  '&:first-of-type': {
    marginLeft: 0,
  },
  '&:last-of-type': {
    marginRight: 0,
  },
});

const AppFooter = styled(Box)({
  position: 'relative',
  bottom: '0px',
  height: 'var(--footer-height)',
  alignContent: 'center',
  justifyContent: 'center',
  display: 'flex',
  flexWrap: 'wrap',
});

export const FooterComponent: React.FC = () => {
  return (
    <AppFooter className="hide-on-fullscreen">
      <StyledList>
        <StyledListItem>
          <Link href="mailto:info@gentian.team" underline="none" color="inherit">
            <Typography variant="body2" color="text.secondary">
              Contact Us
            </Typography>
          </Link>
        </StyledListItem>
        <li>-</li>
        <StyledListItem>
          <Link href="/terms-and-conditions" underline="none" color="inherit">
            <Typography variant="body2" color="text.secondary">
              Terms and Conditions
            </Typography>
          </Link>
        </StyledListItem>
        <li>-</li>
        <StyledListItem>
          <Link href="/privacy-policy" underline="none" color="inherit">
            <Typography variant="body2" color="text.secondary">
              Privacy Policy
            </Typography>
          </Link>
        </StyledListItem>
      </StyledList>
    </AppFooter>
  );
};
