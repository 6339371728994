import { Box, Divider, Typography } from '@mui/material';
import { HTMLAttributes } from 'react';

export interface ContentTileProps extends HTMLAttributes<HTMLDivElement> {
  title?: string;
}

export const ContentTileComponent: React.FC<ContentTileProps> = ({ title, children, ...props }) => (
  <Box {...props}>
    <Typography
      variant="h6"
      className="hide-on-fullscreen"
      style={{
        padding:
          'var(--content-spacing-narrow) var(--content-spacing-narrow) 0 var(--content-spacing-narrow)',
      }}
    >
      {children || title}
    </Typography>
    <Divider
      className="hide-on-fullscreen"
      style={{
        borderTopWidth: 'thin',
        borderBottomWidth: 0,
        paddingBottom: 'var(--content-spacing-narrow)',
      }}
    />
  </Box>
);
