import styled from '@emotion/styled';
import { IProduct } from '../../core/interfaces/product.interface';
import { Box, Divider, Link, Switch, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { OpenInNew } from '@mui/icons-material';

export interface ProductProps {
  product: IProduct;
  selected?: boolean;
  sx?: any;
  onProductSelected?: (product: IProduct) => void;
  onProductDeselected?: (product: IProduct) => void;
}

const ProductContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  border: '1px solid var(--gentian-light-gray)',
  borderRadius: '4px',
  padding: '8px',
  boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.75)',
  backgroundColor: 'white',
});

const ProductImageContainer = styled(Box)({
  width: 80,
  height: 80,
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const ProductImage = styled('img')({
  maxWidth: '100%',
  objectFit: 'contain',
});

const SelectionRow = styled(Box)({
  justifyContent: 'space-evenly',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  width: '216px',
  height: '80px',
});

const ProductDescription = styled(Typography)({
  display: '-webkit-box',
  overflow: 'hidden',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 10,
  textOverflow: 'ellipsis',
  whiteSpace: 'normal',
  fontSize: '12px',
  height: 'fit-content',
  width: '100%',
});

const ProductName = styled(Typography)({
  display: '-webkit-box',
  overflow: 'hidden',
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: 1,
  textOverflow: 'ellipsis',
  whiteSpace: 'normal',
  textAlign: 'start',
  fontWeight: 'bold',
  width: '100%',
  fontSize: '14px',
});

const ProductDetailsLink = styled(Link)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'end',
  width: '100%',
  fontSize: '12px',
  marginTop: '4px',
});

export const ProductComponent: React.FC<ProductProps> = ({
  product,
  selected,
  sx,
  onProductSelected,
  onProductDeselected,
}) => {
  const [isSelected, setIsSelected] = React.useState<boolean>(selected || false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ProductContainer
      sx={{
        ...sx,
        width:
          product.size === 'large' && !isMobile ? 'calc(432px + var(--content-spacing-narrow) * 3)' : '216px',
      }}
      style={{
        boxShadow: isSelected ? '0px 0px 4px 0px var(--gentian-secondary)' : '',
        background: !isSelected ? 'var(--gentian-light-gray)' : '',
      }}
    >
      <SelectionRow>
        <ProductImageContainer>
          <ProductImage style={{ maxWidth: '100%' }} src={product.image} />
        </ProductImageContainer>
        <Switch
          checked={isSelected}
          onChange={(event) => {
            event.target.checked ? onProductSelected?.(product) : onProductDeselected?.(product);
            setIsSelected(event.target.checked);
          }}
        />
      </SelectionRow>

      <Divider
        orientation={product.size === 'large' && !isMobile ? 'vertical' : 'horizontal'}
        sx={{
          width: product.size === 'large' && !isMobile ? '1px' : '100%',
          my: 1,
          height: product.size === 'large' && !isMobile ? '120px' : '1px',
        }}
      />

      <Box sx={{ width: '216px' }}>
        <ProductName>{product.name}</ProductName>
        <ProductDescription>{product.shortDescription} </ProductDescription>
      </Box>

      <ProductDetailsLink href={product.detailsURI} target="_blank">
        Find Out More <OpenInNew fontSize="inherit" style={{ marginLeft: '2px' }} />
      </ProductDetailsLink>
    </ProductContainer>
  );
};
